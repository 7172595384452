import ReactDOM from 'react-dom/client';
import App from '../../App';

import * as React from 'react';
import { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { InputAdornment, IconButton } from '@mui/material';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { readDatabase } from "../../sources/databaseManager.js";
import AdminDashboard from '../onlineStore/adminDashboard.jsx';

import './stylesheet-sign.css';
import Footer from '../footer/footer.jsx';

// import axios from 'axios';
// import { AuthContext } from '../../sources/database/authContext.js';


function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      <Footer />
    </Typography>
  );
}

const defaultTheme = createTheme();

export default function SignIn() {
  // Setting up RecatDom
  const [root, setRoot] = React.useState(null);

  React.useEffect(() => {
    if (root === null) {
      setRoot(ReactDOM.createRoot(document.getElementById('root')));
    }
  }, []);

  // handle manager setting
 const handleManage = async (event) => {
    const email = document.getElementById('username').value;
    const password = document.getElementById('password').value;

    var database = await readDatabase();
    var account = database.accountData.values[0];

    if (email === account.email && password === account.password) {
      root.render(<AdminDashboard email={email} password={password} startINDX={1}/>);
    }
    else {
      alert('Napačno uporabniško ime ali geslo!');
    }
  }

  // Get certain colors
  const primaryColor = getComputedStyle(document.documentElement).getPropertyValue('--primary-color');
  const hoverColor = getComputedStyle(document.documentElement).getPropertyValue('--accent-color');

  const [hover, setHover] = useState(false);

  // Show and hide password
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className='signBG'>
      <ThemeProvider theme={defaultTheme}>
        <Container component="main" maxWidth="xs">
          <button 
            onClick={() => root.render(<App />)}
            className='return-button-SN'
          >
            {'<'} nazaj
          </button>
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: primaryColor }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Vpis
            </Typography>
            <Box noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="username"
                label="Ime uporabnika"
                name="username"
                autoComplete="off"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Geslo"
                id="password"
                autoComplete="off"
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowPassword}
                      >
                        <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                onClick={(event) => handleManage(event)}
                sx={{ mt: 3, mb: 2 }}
                style={{ backgroundColor: hover ? hoverColor : primaryColor }}
              >
                Vpis
              </Button>
              {/* <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2">
                    Ste pozabili geslo?
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="#" variant="body2"
                    onClick={() => ReactDOM.render(<SignUp />, document.getElementById('root'))}
                  >
                    {"Nimate še računa? Registrirajte se"}
                  </Link>
                </Grid>
              </Grid> */}
            </Box>
          </Box>
          <Copyright sx={{ mt: 8, mb: 4 }} />
        </Container>
      </ThemeProvider>
    </div>
  );
}