// =============== IMPORT ALL THE PACKAGES ===============
import './navbarMain.css';
import ReactDOM from 'react-dom/client';
import React from 'react';

import Store  from '../onlineStore/Store.jsx';
import App from '../../App.jsx';

import { Link } from 'react-scroll';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import logo from '../images/production/logo.png';

export default function Navbar({ id, FuseConfigs, TransitionDuration, TransitionStyle, className, name, inHomepage }) {
  const [inHomeDoc, setHomePage] = React.useState(true);
  const [isOpen, setIsOpen] = React.useState(false);
  const [root, setRoot] = React.useState(null);

  React.useEffect(() => {
    if (root === null) {
      setRoot(ReactDOM.createRoot(document.getElementById('root')));
    }

    if (document.getElementById('galerija') === null) {
      setHomePage(false);
    }
  }, []);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  const closeNavbar = () => {
    setIsOpen(false);
  };

  return (
    <section id={id} className={className} name={name}>
      <div className='container-nav'>
        <div className='hamburger-nav' onClick={toggleNavbar}>
          <FontAwesomeIcon
            icon={faBars}
            className='hamburger-icon'
          />
        </div>
        <nav className={`navbar-hamb ${isOpen ? 'open' : ''}`}>
          <div className='navbar-links'>
            <ul className='nav-hamb-links'>
              <li className='nav-item nav-item-hamb'>
                {inHomeDoc ? <Link 
                  onClick={() => closeNavbar()} 
                  className='nav-link-hamb' 
                  to={"home-sect"} 
                  duration={TransitionDuration} 
                  smooth={TransitionStyle} 
                  spy={true}
                >Domov</Link>
                :
                <Link 
                  onClick={() => {closeNavbar(); root.render(<App navigateTo={"home"}/>);}} 
                  className='nav-link-hamb' 
                  to={"home-sect"} 
                  duration={TransitionDuration} 
                  smooth={TransitionStyle} 
                  spy={true}
                >Domov</Link>}
              </li>
              <li className='nav-item nav-item-hamb'>
                {inHomeDoc ? <Link 
                  onClick={() => closeNavbar()} 
                  className='nav-link-hamb' 
                  to={"about-sect"} 
                  duration={TransitionDuration} 
                  smooth={TransitionStyle} 
                  offset={-200} 
                  spy={true}
                >O nas</Link>
                :
                <Link 
                  onClick={() => {closeNavbar(); root.render(<App navigateTo={"about"}/>);}} 
                  className='nav-link-hamb' 
                  to={"about-sect"} 
                  duration={TransitionDuration} 
                  smooth={TransitionStyle} 
                  offset={-200} 
                  spy={true}
                >O nas</Link>}
              </li>
              <li className='nav-item nav-item-hamb'>
                {inHomeDoc ? <Link 
                  onClick={() => closeNavbar()} 
                  className='nav-link-hamb' 
                  to={"galerija-sect"} 
                  duration={TransitionDuration} 
                  smooth={TransitionStyle} 
                  offset={-100} 
                  spy={true}
                >Galerija</Link>
                :
                <Link 
                  onClick={() => {closeNavbar(); root.render(<App navigateTo={"galerija"}/>);}} 
                  className='nav-link-hamb' 
                  to={"galerija-sect"} 
                  duration={TransitionDuration} 
                  smooth={TransitionStyle} 
                  offset={-100} 
                  spy={true}
                >Galerija</Link>}
              </li>
              <li className='nav-item nav-item-hamb'>
                {inHomeDoc ? <Link 
                  onClick={() => closeNavbar()} 
                  className='nav-link-hamb' 
                  to={"storeRedirect-sect"} 
                  duration={TransitionDuration} 
                  smooth={TransitionStyle} 
                  offset={-100} 
                  spy={true}
                >Spletna Trgovina</Link>
                :
                <Link 
                  onClick={() => {closeNavbar(); root.render(<Store />);}} 
                  className='nav-link-hamb' 
                  to={"storeRedirect-sect"} 
                  duration={TransitionDuration} 
                  smooth={TransitionStyle} 
                  offset={-100} 
                  spy={true}
                >Spletna Trgovina</Link>}
              </li>
              <li className='nav-item nav-item-hamb'>
                {inHomeDoc ? <Link 
                  onClick={() => closeNavbar()} 
                  className='nav-link-hamb' 
                  to={"contact-sect"} 
                  duration={TransitionDuration} 
                  smooth={TransitionStyle} 
                  offset={-200} 
                  spy={true}
                >Kontakt</Link>
                :
                <Link 
                  onClick={() => {closeNavbar(); root.render(<App navigateTo={"contact"}/>);}} 
                  className='nav-link-hamb' 
                  to={"contact-sect"} 
                  duration={TransitionDuration} 
                  smooth={TransitionStyle} 
                  offset={-200} 
                  spy={true}
                >Kontakt</Link>}
              </li>
            </ul>
          </div>
        </nav>
        <nav className='navbar-norm'>
          <div className='navbar-logo'>
            {inHomeDoc === true ? 
              <Link 
                className='title-main-home'
                activeClass="active"
                to={"home-sect"} 
                duration={TransitionDuration} 
                smooth={TransitionStyle} 
                spy={true}
                style={{ cursor: 'pointer' }}
              >
                <img src={logo} alt='logo' className='nav-bar-logo'/>
              </Link>
            :
              <Link
                className='title-main-home'
                activeClass="active"
                style={{ cursor: 'pointer' }}
                onClick={() => root.render(<App navigateTo={"home"}/>)}
              >
                <img src={logo} alt='logo' className='nav-bar-logo'/>
              </Link>
            }
          </div>
          <div className='navbar-links'>
            <ul>
              <li className='nav-item'>
                {inHomeDoc === true ? 
                  <Link 
                    className="nav-link" 
                    activeClass="active"
                    to={"home-sect"}
                    duration={TransitionDuration} 
                    smooth={TransitionStyle} 
                    spy={true}
                    style={{ cursor: 'pointer' }}
                  >
                    Domov
                  </Link>
                :
                  <Link
                    className="nav-link" 
                    activeClass="active"
                    style={{ cursor: 'pointer' }}
                    onClick={() => root.render(<App navigateTo={"home"}/>)}
                  >
                    Domov
                  </Link>
                }
              </li>
              <li className='nav-item'>
                { inHomeDoc === true ?
                  <Link 
                    className="nav-link" 
                    activeClass="active"
                    to={"about-sect"}
                    duration={TransitionDuration} 
                    smooth={TransitionStyle} 
                    offset={-200}
                    spy={true}
                    style={{ cursor: 'pointer' }}
                  >
                    O nas
                  </Link>
                :
                  <Link
                    className="nav-link" 
                    activeClass="active"
                    style={{ cursor: 'pointer' }}
                    onClick={() => root.render(<App navigateTo={"about"}/>)}
                  >
                    O nas
                  </Link>}
              </li>
              <li className="nav-item dropdown">
                {inHomeDoc === true ?
                  <Link 
                    className="nav-link" 
                    activeClass="active"
                    to={"galerija-sect"}
                    duration={TransitionDuration} 
                    smooth={TransitionStyle} 
                    offset={-100}
                    spy={true}
                    style={{ cursor: 'pointer' }}
                  >
                    Galerija
                  </Link>
                :
                  <Link
                    className="nav-link" 
                    activeClass="active"
                    style={{ cursor: 'pointer' }}
                    onClick={() => root.render(<App navigateTo={"galerija"}/>)}
                  >
                    Galerija
                  </Link>
                }
              </li>
              <li className="nav-item dropdown">
                {inHomeDoc === true ?
                  <Link 
                    className="nav-link" 
                    activeClass="active"
                    to={"storeRedirect-sect"}
                    duration={TransitionDuration} 
                    smooth={TransitionStyle} 
                    offset={-100}
                    spy={true}
                    style={{ cursor: 'pointer' }}
                  >
                    Spletna Trgovina
                  </Link>
                :
                  <Link
                    className="nav-link" 
                    activeClass="active"
                    style={{ cursor: 'pointer' }}
                    onClick={() => root.render(<Store />)}
                  >
                    Spletna Trgovina
                  </Link>
                }
              </li>
              <li className='nav-item'>
                { inHomeDoc === true ?
                  <Link 
                    className="nav-link" 
                    activeClass="active"
                    to={"contact-sect"}
                    duration={TransitionDuration} 
                    smooth={TransitionStyle} 
                    offset={-200}
                    spy={true}
                    style={{ cursor: 'pointer' }}
                  >
                    Kontakt
                  </Link>
                :
                  <Link
                    className="nav-link"
                    activeClass="active"
                    style={{ cursor: 'pointer' }}
                    onClick={() => root.render(<App navigateTo={"contact"}/>)}
                  >
                    Kontakt
                  </Link>
                }
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </section>
  );
}
