import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

// Function to read stats from the server
async function readStats() {
  try {
    const response = await fetch('http://127.0.0.1:5000/api/stats/get');
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const stats = await response.json();
    return stats;
  } catch (error) {
    console.error('Error reading stats:', error);
  }
}

// Function to check if the screen si wider than a certain threshold of px
export function useIsScreenSmall() {
  const [isScreenSmall, setIsScreenSmall] = useState(false);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsScreenSmall(window.innerWidth < 950);
    };

    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);

    // Cleanup function
    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);

  return isScreenSmall;
}

export function useIsScreenSize({size, biggerThan=false, smallerThan=false, equalTo=false}) {
  const [isScreenSize, setIsScreenSize] = useState(false);

  useEffect(() => {
    const checkScreenSize = () => {
      if (biggerThan) setIsScreenSize(window.innerWidth > size);
      else if (smallerThan) setIsScreenSize(window.innerWidth < size);
      else if (equalTo) setIsScreenSize(window.innerWidth = size);
      else setIsScreenSize(false);
    };

    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);

    // Cleanup function
    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);

  return isScreenSize;
}

// Function to track stats and send them to the server
async function trackStats({page}) {
  try {
    let userId = localStorage.getItem('userId');
    if (!userId) {
      userId = uuidv4();
      localStorage.setItem('userId', userId);
    }

    const stat = {
      userId,
      page,
      os: navigator.platform,
      deviceType: /Mobi|Android/i.test(navigator.userAgent) ? 'Mobile' : 'Desktop',
      time: new Date(),
      // Add more fields as needed
    };

    const response = await fetch('http://127.0.0.1:5000/api/stats/add', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(stat),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
  } catch (error) {
    console.error('Error tracking stats:', error);
  }
}

// Function that compares a date's values to the current date's values
function compareDates(date1, date2, type) {
  try {
    if (type === 'day') {
      return date1.getDate() === date2.getDate();
    } 

    else if (type === 'month') {
      return date1.getMonth() === date2.getMonth();
    } 

    else if (type === 'year') {
      return date1.getFullYear() === date2.getFullYear();
    }
  } catch (error) {
    console.error('Error comparing dates:', error);
  }
}


// Function to get the range of dates for the data
async function getDateRange() {
  try {
    const response = await readStats(); 
    const data = await response.data;
    const date = new Date();
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();

    if (!response.success) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const statData = Object.values(data.values);
    var datess = [];

    for (let i = 0; i < statData.length; i++) {
      let usrStats = statData[i].visitLog

      for (let j = 0; j < usrStats.length; j++) {
        datess.push(usrStats[j][0])
      }
    }

    var dates = datess.map(dateString => new Date(dateString));

    var minDate = new Date(Math.min.apply(null, dates));
    var maxDate = new Date(Math.max.apply(null, dates));

    // Check if minDate and maxDate are more than a year apart
    if (maxDate.getFullYear() - minDate.getFullYear() > 1) {
      minDate = new Date(maxDate.getFullYear() - 1, maxDate.getMonth(), maxDate.getDate());
    }

    return [minDate, maxDate];

  } catch (error) {
    console.error('Error getting date range:', error);
  }
}

// Function to get all the dates in a date diffirence
function getDatesInRange(dates = null, startDate, endDate) {
  try {
    if (dates === null) {
      dates = [];
      let currentDate = new Date(startDate.getTime());

      while (currentDate <= endDate) {
        dates.push(new Date(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
      }
    }

    return dates.filter(date => date >= startDate && date <= endDate);
  } catch (error) {
    console.error('Error getting dates in range:', error);
  }
}

// Function to get the date diffirence between two dates and the type
function getDateDifference(startDate, endDate, type) {
  try {
    const startYear = startDate.getFullYear();
    const startMonth = startDate.getMonth();
    const startDay = startDate.getDate();
    const endYear = endDate.getFullYear();
    const endMonth = endDate.getMonth();
    const endDay = endDate.getDate();

    const date1 = new Date(startYear, startMonth, startDay);
    const date2 = new Date(endYear, endMonth, endDay);

    if (type === 'day') {
      const dayDifference = Math.floor((date2 - date1) / (1000 * 60 * 60 * 24));
      return dayDifference;
    } 

    else if (type === 'month') {
      const monthDifference = (endYear - startYear) * 12 + (endMonth - startMonth);
      return monthDifference;
    } 

    else if (type === 'year') {
      const yearDifference = endYear - startYear;
      return yearDifference;
    }

    return null;
  } catch (error) {
    console.error('Error getting date diffirence:', error);
  }
}

// Function to get the name of a month from a number
function getMonthName(monthNumber) {
  const date = new Date(2000, monthNumber); // The year doesn't matter
  return date.toLocaleString('default', { month: 'long' });
}

// Function to get all the total views of the website for the day
async function readTotalViews(datePR=false) {
  try {
    const response = await readStats(); 
    const data = await response.data;
    const date = new Date();

    if (!response.success) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    var users = Object.values(data.values);
    var totalViews = 0;

    for (let k = 0; k < users.length; k++) {
      totalViews += parseInt(users[k].totalVisits);
    }

    if (datePR === false) {
      return totalViews;
    }
    else if (datePR === true) {
      var statData = Object.values(data.values);
      var viewsLog = [];
      var validDates = [];

      for (let i = 0; i < statData.length; i++) {
        let usrStats = statData[i].visitLog
  
        for (let j = 0; j < usrStats.length; j++) {
          viewsLog.push(usrStats[j][0])
        }
      }

      var dateRange = await getDateRange()
      var dates = getDatesInRange(null, dateRange[0], dateRange[1]);

      viewsLog.forEach((view) => {
        let viewDate = new Date(view);
        if (dates.some(date => date.getDay() === viewDate.getDay())) {
          validDates.push(viewDate);
        }
      });

      var monthCounts = new Array(getDateDifference(dateRange[0], dateRange[1], 'month') + 1).fill(0);

      validDates.forEach((date) => {
        let monthIndex = getDateDifference(dateRange[0], date, 'month');

        monthCounts[monthIndex]++;
      });

      return monthCounts;
    }

  } catch (error) {
    console.error('Error getting total views:', error);
  }
}

// Function to make graph data for the views and the dates of a website
async function makeViewGraphData() {
  try {
    const response = await readStats();
    const data = await response.data; 
    const date = new Date();
    const month = date.getMonth();

    if (!response.success) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    var dateRange = await getDateRange();

    var xAxis = [];

    if (getDateDifference(dateRange[0], dateRange[1], 'month') !== 0) {
      for (let i = 0; i < getDateDifference(dateRange[0], dateRange[1], 'month'); i++) {
        xAxis.push(dateRange[0].getMonth() + i);
      }
    }
    else {
      xAxis.push(dateRange[0].getMonth());
    }

    var yAxis = await readTotalViews(true);

    return [xAxis, yAxis];

  } catch (error) {
    console.error('Error making view graph data:', error);
  }
}

export { readStats, trackStats, makeViewGraphData, getMonthName };