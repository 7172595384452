// LoadingAnimation.jsx
import React from 'react';
import './loader.css'; // Import the CSS file

function LoadingAnimation({boxed}) {
  return (
    <div className='SiteBG' style={boxed ? {width: "auto !important", height: "auto !important"} : {}}>
      <div className="loading">
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
      </div>
    </div>
  );
}

export default LoadingAnimation;