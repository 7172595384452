import emailjs from '@emailjs/browser';
import { getEmailJSKey, getEmailJSPublicKey } from './databaseManager';

export default async function sendEmail(e, subject, email, message) {
  e.preventDefault();

  try {
    var serviceKey = await getEmailJSKey();
    var publicAPIKey = await getEmailJSPublicKey();
  } catch (error) {
    console.error('Error getting API keys: ', error);
    return 0;
  }
  const templateID = 'template_7jqpem4';
  const toEmail = 'info@mizarstvo-karmen.si';
  const domain = 'https://mizarstvo-karmen.si';

  const messageSend = `
======= MAIL IZ SPLETNE STRANI '${domain}' =======
Od-email: '${email}'
Do-email: '${toEmail}'
Zadeva: '${subject}'
${'='.repeat(domain.length + 32 - 9)}

Pošiljatel pošilja sporočilo:

"${message}"

${'='.repeat(domain.length + 32 - 9)}`;

  let result = await emailjs.send(serviceKey, templateID, {
    subject: subject,
    message: messageSend,
    to_email: toEmail,
    from_email: email,
    reply_to: email
  },{
    publicKey: publicAPIKey
  });

  if (result.status === 200) {
    return 1;
  }
  return 0;
}