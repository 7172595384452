import React, { useState } from 'react';
import $ from 'jquery';

import './storeTopBar.css';

var activeTab = 0;

export default function StoreTopBar({className, style, activeTab, onUpdate, startINDX = 0}) {
  function tabsOnCLick (event) {
    var tabs = $('.tabs > li');

    tabs.removeClass('active');
    console.log(tabs);
    if (event.target.id === '1') {
      let link = document.getElementById('11');
      link.className += ' active';

      activeTab = 0;
    }
    else if (event.target.id === '2') {
      let link = document.getElementById('22');
      link.className += ' active';

      activeTab = 1;
    }

    onUpdate(activeTab);
  }

  return (
    <section id='store-top-bar' className={className} style={style}>
      <div className='tab-container'>
        <ul className="tabs clearfix" >
          {/* <li id="11" className={startINDX === 0? 'active' : ''}>
            <p id="1" onClick={(e) => tabsOnCLick(e)}>AKTIVNOST SPLETNE STRANI</p> 
          </li> */}
          <li className={startINDX === 1? 'active' : ''} id='22'> 
            <p id="2">SPLETNA TRGOVINA</p> 
          </li>
        </ul>
      </div>
      <div className='outer-circle'></div>
    </section>
  );
}

export function readActiveTab() {
  return activeTab;
}

export function setActiveTab(tab) {
  activeTab = tab;
}