import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';

import './footer.css';
import SignIn from '../sign-in/signIn.jsx';

export default function Footer({className, style, ...restProps}) {
  const [root, setRoot] = React.useState(null);

  useEffect(() => {
    setRoot(ReactDOM.createRoot(document.getElementById('root')));
  }, []);

  function sendToManager(){
    root.render(<SignIn/>);
    return 0;
  }

  return (
    <section id='footer' className={className} style={style}>
      <div className='fotter-cont'>
        <hr />
        <p><a 
          href="#l" 
          onClick={() => sendToManager()}
          className='cp-link'
        >©</a> 2024 Mizarstvo Karmen d.o.o.</p>
        {/* <p>Made by <strong><a 
          className='footer-website-link'
          href='http://jlstudios.studiobleu.si/'
        >JL Studios</a></strong></p> */}
      </div>
    </section>
  );
}