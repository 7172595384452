import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";

import AdminDashboard from "./adminDashboard.jsx";
import { setCategoryItems } from "./Store.jsx";

import { modifyDatabase, parseSpecs, readShopItems, parseTags } from "../../sources/databaseManager.js";

import "./editPageStore.css";

import { Button } from '@mui/material';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/lab/Autocomplete';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faFileLines, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";


export default function EditPage({ setPageData, manualData, className, itemIndex }) {
  console.log('Item Index: ',itemIndex)

  var [defaultImage, setDefaultImage] = useState("https://placehold.co/800@3x.png?text=Upload+Image");
  
  const accentColor = getComputedStyle(document.documentElement).getPropertyValue('--accent-color');
  const primaryColor = '#998867'

  const [selectedImage, setSelectedImage] = useState(defaultImage);

  const [root, setRoot] = useState(null);
  const [defView, setDefView] = useState(false);

  const [currTitle, setCurrTitle] = useState(setPageData.title);
  const [currDescription, setCurrDescription] = useState(setPageData.description);
  const [currPrice, setCurrPrice] = useState(setPageData.price);
  const [currTags, setCurrTags] = useState(setPageData.tags);
  const [editedData, setEditedData] = useState(setPageData);

  const [currDefTitle, setCurrDefTitle] = useState(setPageData.title);
  const [currDefDescription, setCurrDefDescription] = useState(setPageData.description);
  const [currDefPrice, setCurrDefPrice] = useState(setPageData.price);
  const [currDefSpecCategory, setCurrDefSpecCategory] = useState(setPageData["spec-category"]);

  const [currSpecIndex, setCurrSpecIndex] = useState(0);
  const [inputValue, setInputValue] = useState("#" + setPageData.tags.join(', #'));
  
  useEffect(() => {
    try {
      setDefaultImage(setPageData.specs[0].image);
      setSelectedImage(setPageData.specs[0].image);
    }
    catch (e) {
      alert('Error while loading manual data: ' + e);
    }
  }, [setPageData, manualData]);

  useEffect(() => {
    if (root === null) {
      setRoot(ReactDOM.createRoot(document.getElementById('root')));
    }
  }, []);

  const handleImageUpload = (event, specIndx) => {
    const file = event.target.files[0];
    const reader = new FileReader();
  
    reader.onloadend = () => {
      const img = new Image();
      img.src = reader.result;
  
      img.onload = () => {
        const maxWidth = 1920; // 1080p width
        const maxHeight = 1080; // 1080p height
  
        if (img.width > maxWidth || img.height > maxHeight) {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
  
          let width = img.width;
          let height = img.height;
  
          // If the image width is larger than the max width, scale down the height proportionally
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
  
          // If the image height is larger than the max height, scale down the width proportionally
          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }
  
          canvas.width = width;
          canvas.height = height;
  
          ctx.drawImage(img, 0, 0, width, height);
  
          setSelectedImage(canvas.toDataURL(file.type));

          setPageData.specs[specIndx].image = canvas.toDataURL(file.type);

          setEditedData({
            title: editedData.title,
            description: editedData.description,
            price: editedData.price,
            "spec-category": setPageData["spec-category"],
            specs: setPageData.specs,
            tags: currTags,
          })
        } else {
          setSelectedImage(reader.result);

          setPageData.specs[specIndx].image = reader.result;

          setEditedData({
            title: editedData.title,
            description: editedData.description,
            price: editedData.price,
            "spec-category": setPageData["spec-category"],
            specs: setPageData.specs,
            tags: currTags,
          })
        }
      };
    };
  
    if (file) {
      reader.readAsDataURL(file);
    } else {
      setSelectedImage(defaultImage);
    }
  };

  const handleSpecChange = (event, index, specs) => {
    setCurrTitle(specs[index].title);
    setCurrDescription(specs[index].description);
    setCurrPrice(specs[index].price);
    setSelectedImage(specs[index].image);
    setCurrTags(setPageData.tags);
    setCurrSpecIndex(index);

    setDefView(false);

    setPageData.specs = specs

    setTimeout(() => {
      setEditedData({
        title: setPageData.title,
        description: setPageData.description,
        price: setPageData.price,
        "spec-category": setPageData["spec-category"],
        specs: setPageData.specs,
        tags: setPageData.tags,
      })
    }, 100);

  };

  const handleInputChange = (event, type, specIndex) => {
    console.log('specIndex', specIndex);
    if (specIndex !== 'def') {
      console.log('Spec Index: ', specIndex);
      if (type === 'title') {
        setCurrTitle(event.target.value);
        setPageData.specs[specIndex].title = event.target.value;
      }
      else if (type === 'description') {
        setCurrDescription(event.target.value);
        setPageData.specs[specIndex].description = event.target.value;
      }
      else if (type === 'price') {
        setCurrPrice(event.target.value);
        setPageData.specs[specIndex].price = event.target.value;
      }
      else if (type === 'tags') {
        setCurrTags(event.target.value);
        setPageData.tags = parseTags(event.target.value)
      }
      else if (type === 'spec-title') {
        setPageData.specs[specIndex]["spec-name"] = event.target.value;
      }
      else {
        console.error('Error while changing input value!, type: ', type);
        return;
      }

      setEditedData({
        title: editedData.title,
        description: editedData.description,
        price: editedData.price,
        "spec-category": setPageData["spec-category"],
        specs: setPageData.specs,
        tags: currTags,
      })

    } 
    else if (specIndex === 'def') {
      if (type === 'title') {
        setCurrDefTitle(event.target.value);
        setPageData.title = event.target.value;
      }
      else if (type === 'description') {
        setCurrDefDescription(event.target.value);
        setPageData.description = event.target.value;
      }
      else if (type === 'price') {
        setCurrDefPrice(event.target.value);
        setPageData.price = event.target.value;
      }
      else if (type === 'tags') {
        setCurrTags(event.target.value);
        setPageData.tags = parseTags(event.target.value)
      }
      else if (type === 'spec-category') {
        setCurrDefSpecCategory(event.target.value);
        setPageData["spec-category"] = event.target.value;
      } 
      else {
        console.error('Error while changing input value!, type: ', type);
        return;
      }

      setEditedData({
        title: setPageData.title,
        description: setPageData.description,
        price: setPageData.price,
        "spec-category": setPageData["spec-category"],
        specs: setPageData.specs,
        tags: currTags,
      })

    }
    else {
      console.error('Error while changing input value!, specIndex: ', specIndex);
      return;
    }
  }
    
  const handleAddSpec = (event, data) => {
    if (data.specs.length < 8) {
      data.specs.push({
        title: `++ -${data.specs.length + 1}-`,
        description: `++ -${data.specs.length + 1}-`,
        price: "==",
        "spec-name": `specifikacija ${data.specs.length + 1}`,
        image: "https://placehold.co/600x400?text=Dodaj+sliko",
      });
      root.render(<EditPage setPageData={data} manualData={true} itemIndex={itemIndex} />);
    }
    else {
      alert('Maksimalno število specifikacij je 8!');
    }
  }

  function Selection() {
    const [textSpecValue, setTextSpecValue] = useState(
      setPageData.specs.map((item) => item["spec-name"])
    );

    const handleSpecDelete = (event, index) => {
      if (setPageData.specs.length !== 1) {
        setPageData.specs.splice(index, 1);
        console.log('Specs after delete: ', setPageData.specs);
        root.render(<EditPage setPageData={setPageData} manualData={true} itemIndex={itemIndex} />);
      }
      else {
        alert('Mora biti usaj ena specifikacija za vsak izdelek!');
      }
    }

    const handleDefaultValues = (event) => {
      setDefView(true);
    }

    const handleSpecNameChange = (event, index) => {
      setPageData.specs[index]["spec-name"] = event.target.value;
      setTextSpecValue((prev) => {
        let temp = [...prev];
        temp[index] = event.target.value;
        return temp;
      });
    }

    let res = [];

    var specsMD = parseSpecs(setPageData.specs, setPageData)

    for (let i = 0;i < specsMD.length; i++) {
      res.push(
        <div
          style={{
            width: `${Math.round(100 / specsMD.length)}%`,
            margin: '3px',
            padding: '10px',
            height: 'stretch',
            borderRadius: '10px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            backgroundColor: accentColor,
            color: 'white',
          }}
          className={`edit-item-select-item ${i}`}
          >
          <FontAwesomeIcon icon={faEye} className="eye-item" onClick={(e) => handleSpecChange(e, i, specsMD)} />
          <TextField
            className={`edit-item-select-item-text ${i}`}
            id={`spec-${i}`}
            variant="standard"
            value={textSpecValue[i]}
            defaultValue={specsMD[i]["spec-name"]}
            onChange={(e) => handleSpecNameChange(e, i)}
            required
            autoComplete="off"
            style={{
              width: '100%',
              height: '90%',
              color: 'white',
              textAlign: 'center',
              fontWeight: 'bold',
            }}
            sx={{
              '& .MuiInput-underline:after': {
                borderBottomColor: `var(--bg-accent) !important`
              },
            }}
          />
          <FontAwesomeIcon icon={faTrash} className='trash-item' onClick={(e) => handleSpecDelete(e, i)} />
        </div>
      )
    }

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <FontAwesomeIcon icon={faFileLines} className='plus-item' style={{
          padding: '10px',
          margin: '3px',
          marginLeft: '10px',
          fontSize: 'stretch',
          height: '32px',
          borderRadius: '10px',
          backgroundColor: accentColor,
          color: 'white',
          transition: 'all 0.3s ease-in-out',
        }} onClick={(e) => handleDefaultValues(e)}/>
        {res}
        <FontAwesomeIcon icon={faPlus} className='plus-item' style={{
          padding: '10px',
          margin: '3px',
          marginRight: '10px',
          height: '32px',
          fontSize: 'stretch',
          borderRadius: '10px',
          backgroundColor: accentColor,
          color: 'white',
          transition: 'all 0.3s ease-in-out',
        }} onClick={(e) => handleAddSpec(e, setPageData)} />
      </div>
    );
  }

  // Save the item to the database
  const handleShopItemSave = async (event, manual=false, indx=null) => {
    for (let k = 0;k < editedData.specs.length;k++) {
      if (editedData.specs[k].title === '' || editedData.specs[k].title === `++ -${k + 1}-`) {
        alert(`Vnesite ime za predmet "${k + 1}", ali ga pa spremenite!`);
        return;
      }
      else if (editedData.specs[k].description === '' || editedData.specs[k].description === `++ -${k + 1}-`) {
        alert('Vnesite opis za predmet ' + (k + 1) + ', ali ga pa spremenite!');
        return;
      }
      else if (editedData.specs[k].price === '' || editedData.specs[k].price === '==') {
        alert('Vnesite ceno za predmet ' + (k + 1) + ', ali ga pa spremenite!');
        return;
      }
      else if (editedData.specs[k].image === 'https://placehold.co/600x400?text=Dodaj+sliko') {
        alert('Naložite sliko za predmet ' + (k + 1) + ', ali ga pa spremenite!');
        return;
      }
    }

    if (editedData.title === '') {
      alert(`Vnesite ime za predmet`);
      return;
    }
    else if (editedData.description === '') {
      alert('Vnesite opis za predmet!');
      return;
    }
    else if (editedData.price === '') {
      alert('Vnesite ceno za predmet!');
      return;
    }
    else if (editedData["spec-category"] === '') {
      alert('Dodaj ime kategorije za specifikacije!');
      return;
    }

    let temp_tags = parseTags(document.getElementById('kategorija').value)

    console.log('Edited Data -> T: ', temp_tags)

    let saveData = {
      title: editedData.title,
      description: editedData.description,
      price: editedData.price,
      "spec-category": editedData["spec-category"],
      specs: editedData.specs,
      tags: temp_tags,
    }

    for (let k = 0; k < editedData.specs.length; k++) {
      let elem = document.getElementById(`spec-${k}`);

      saveData.specs[k]["spec-name"] = elem.value;
    }

    if (manual === true && indx !== null) {
      let shopItems = await readShopItems({refresh: true});
      console.log('Shop Items Index: ', indx);
      shopItems[indx] = saveData;

      console.log('Shop Items -> after edit: ', shopItems);

      await modifyDatabase('shopItems', 'replace', 'values', shopItems)
      alert('Predmet uspešno posodobljen!');

      root.render(<AdminDashboard startINDX={1}/>);
      return;
    }

    var shopItems = await readShopItems({refresh: true});
    shopItems.push(saveData);

    console.log('Shop Items -> after edit - 2: ', shopItems);

    await modifyDatabase('shopItems', 'replace', 'values', shopItems)
    alert('Predmet uspešno dodan!');

    root.render(<AdminDashboard startINDX={1}/>);
    return;
  };

  // Suggestion dropdown Data
  const getCategorySuggestions = async () => {
    let shopItems = await readShopItems()
    var categories = setCategoryItems(shopItems)

    return categories;
  }

  // Categories INPUT
  const handleCatInput = (event, value) => {
    setInputValue(value);
    setPageData.tags = parseTags(value);
  };

  const handleSelect = (event, value) => {
    if (value) {
      const parts = inputValue.split(',').map(tag => tag.trim());
      parts.pop();
      const uniqueParts = [...new Set(parts.filter(tag => tag !== value))];
      uniqueParts.push(value);
  
      setInputValue(uniqueParts.join(', '));
    }
  };

  // LOAD shop items on startup
  const [ShopItemsLoaded, setShopItemsLoaded] = useState([]);
  const [ShopItemsLoadedBool, setShopItemsLoadedBool] = useState(false);

  if (!ShopItemsLoadedBool) {
    getCategorySuggestions().then((data) => {
      setShopItemsLoaded(data.map((item) =>
        '#' + item
      ));
      setShopItemsLoadedBool(true);
    });
  }
  
  return (
    <div className={`${className} edit-page-main`}>
      <div className="edit-page-cont">
        <div className="edit-item-select-cont">
          <button
            onClick={() => root.render(<AdminDashboard startINDX={1}/>)}
            className='return-button-SN return-button-EP'
          >
            {'<'} nazaj
          </button>
          <div className="edit-item-select">
            <Selection />
          </div>
        </div>
        {defView?
          <div className={`def-edit-main ${className}`}>
            <div className="def-edit-r">
              <div style={{display:'flex',flexDirection:'column',width:'50%'}}>
                <h2>Ime predmeta</h2>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="naslov-def"
                  label="Naslov predmeta"
                  name="Naslov"
                  defaultValue={setPageData.title}
                  value={currDefTitle}
                  autoComplete="off"
                  onChange={(e) => handleInputChange(e, 'title', 'def')}
                  sx={{
                    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: `${accentColor} !important`,
                    },
                    '& .MuiInputLabel-outlined.Mui-focused': {
                      color: `${accentColor} !important`,
                    },
                    width: 'stretch',
                    margin: '20px',
                  }}
                />
                <h2>Osnovna cena predmeta</h2>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="cena-def"
                  label="Cena predmeta"
                  name="Cena"
                  defaultValue={setPageData.price}
                  value={currDefPrice}
                  autoComplete="off"
                  onChange={(e) => handleInputChange(e, 'price', 'def')}
                  sx={{
                    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: `${accentColor} !important`,
                    },
                    '& .MuiInputLabel-outlined.Mui-focused': {
                      color: `${accentColor} !important`,
                    },
                    width: 'stretch',
                    margin: '20px',
                  }}
                />
                <h2>Kategorija specifikacij predmeta</h2>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="spec-category-def"
                  label="Kategorija specifikacij"
                  name="Kategorija"
                  defaultValue={setPageData["spec-category"]}
                  value={currDefSpecCategory}
                  autoComplete="off"
                  onChange={(e) => handleInputChange(e, 'spec-category', 'def')}
                  sx={{
                    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: `${accentColor} !important`,
                    },
                    '& .MuiInputLabel-outlined.Mui-focused': {
                      color: `${accentColor} !important`,
                    },
                    width: 'stretch',
                    margin: '20px',
                  }}
                />
              </div>
              <div style={{display:'flex',flexDirection:'column',width:'50%'}}>
                <h2>Opis predmeta</h2>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  multiline
                  minRows={16}
                  id="opis-def"
                  label="Opis predmeta"
                  name="Opis"
                  defaultValue={setPageData.description}
                  value={currDefDescription}
                  onChange={(e) => handleInputChange(e, 'description', 'def')}
                  sx={{
                    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: `${accentColor} !important`,
                    },
                    '& .MuiInputLabel-outlined.Mui-focused': {
                      color: `${accentColor} !important`,
                    },
                    width: 'stretch',
                    margin: '20px',
                  }}
                />
              </div>
            </div>
            <div style={{
                margin: '15px',
              }}>
              <Autocomplete
                id="kategorija"
                className="category-edit-inp"
                options={ShopItemsLoaded}
                getOptionLabel={(option) => option}
                filterOptions={(options, params) => {
                  const parts = params.inputValue.split(',');
                  const lastPart = parts[parts.length - 1].trim();
                  return options.filter((option) =>
                    option.toLowerCase().includes(lastPart.toLowerCase())
                  );
                }}
                value={inputValue}
                onChange={handleSelect}
                inputValue={inputValue}
                onInputChange={handleCatInput}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    margin="normal"
                    required
                    fullWidth
                    defaultValue={setPageData.tags.join(', #')}
                    id="kategorija"
                    label="kategorije predmeta"
                    name="kategorije"
                    autoComplete="off"
                  />
                )}
              />
              <p
                style={{ textAlign: 'left', width: 'stretch'}}
              >
                Kategorija se začne z '#' in ločuje z vejico. 
                <br/>Primer: "#spalnice, #kuhinje, #kopalnice"
              </p>
            </div>
          </div>
        :
        <div className="edit-main-cont-left-rt">
          <div className="edit-item-curr">
            <div className="edit-left-side">
              {selectedImage && (
                <img
                  src={selectedImage}
                  id="image-preview"
                  alt="Preview"
                  className="edit-image-preview"
                />
              )}
              <Button
                variant="contained"
                component="label"
                className='edit-image-upload-btn'
                sx={{
                  backgroundColor: accentColor, 
                  '&:hover': {
                    backgroundColor: `${primaryColor} !important`,
                  },
                }}
                style={{ marginTop: '20px' }}
              >
                -- Dodaj sliko tukaj --
              <input 
                type="file" 
                accept="image/*" 
                onChange={(e) => handleImageUpload(e, currSpecIndex)} 
                className="edit-image-upload"
              />
              </Button>
            </div>
            <div className="edit-right-side">
              <TextField
                margin="normal"
                required
                fullWidth
                id="naslov"
                label="Naslov predmeta"
                name="Naslov"
                autoComplete="off"
                defaultValue={currTitle}
                value={currTitle}
                onChange={(e) => handleInputChange(e, 'title', currSpecIndex)}
                autoFocus
                sx={{
                  '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: `${accentColor} !important`,
                  },
                  '& .MuiInputLabel-outlined.Mui-focused': {
                    color: `${accentColor} !important`,
                  },
                  width: 'stretch',
                  marginBottom: '20px',
                }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                multiline
                minRows={7}
                id="opis"
                label="Opis predmeta"
                name="Opis"
                defaultValue={currDescription}
                value={currDescription}
                onChange={(e) => handleInputChange(e, 'description', currSpecIndex)}
                autoComplete="off"
                sx={{
                  '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: `${accentColor} !important`,
                  },
                  '& .MuiInputLabel-outlined.Mui-focused': {
                    color: `${accentColor} !important`,
                  },
                  width: 'stretch',
                  marginBottom: '20px',
                }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="cena"
                label="Cena predmeta"
                name="Cena"
                defaultValue={currPrice}
                value={currPrice}
                onChange={(e) => handleInputChange(e, 'price', currSpecIndex)}
                autoComplete="off"
                sx={{
                  '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: `${accentColor} !important`,
                  },
                  '& .MuiInputLabel-outlined.Mui-focused': {
                    color: `${accentColor} !important`,
                  },
                  width: 'stretch',
                  marginBottom: '20px',
                }}
              />
              <div className="kategorije-cont-edit" >
                <div className="category-edit-inp" style={{
                    margin: '10px',
                  }}>
                  <Autocomplete
                    id="kategorija"
                    className="category-edit-inp"
                    options={ShopItemsLoaded}
                    getOptionLabel={(option) => option}
                    filterOptions={(options, params) => {
                      const parts = params.inputValue.split(',');
                      const lastPart = parts[parts.length - 1].trim();
                      return options.filter((option) =>
                        option.toLowerCase().includes(lastPart.toLowerCase())
                      );
                    }}
                    value={inputValue}
                    onChange={handleSelect}
                    inputValue={inputValue}
                    onInputChange={handleCatInput}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        margin="normal"
                        required
                        fullWidth
                        defaultValue={setPageData.tags.join(', #')}
                        id="kategorija"
                        label="kategorije predmeta"
                        name="kategorije"
                        autoComplete="off"
                      />
                    )}
                  />
                  <p
                    style={{ textAlign: 'left', width: 'stretch'}}
                  >
                    Kategorija se začne z '#' in ločuje z vejico. 
                    <br/>Primer: "#spalnice, #kuhinje, #kopalnice"
                  </p>
                </div>
              </div>
              <Button 
                variant="contained"
                className='edit-save-btn'
                onClick={(e) => handleShopItemSave(e, manualData, itemIndex)}
                sx={{
                  backgroundColor: accentColor, 
                  '&:hover': {
                    backgroundColor: `${primaryColor} !important`,
                  },
                  width: '40vw',
                  marginTop: '20px',
                }}
              >
                {manualData? "Shrani izdelek" : "Dodaj izdelek"}
              </Button>
            </div>
          </div>
        </div>
        }
      </div>
    </div>
  );
} 