const importAll = (r) => {
  let images = [];
  r.keys().map((item, index) => { images.push(r(item)); });
  return images;
}

const imagesHome = importAll(require.context('./navigation/images/production/home-slider', false, /\.(png|jpe?g|svg)$/))

const galImagesCat1 = importAll(require.context('./navigation/images/production/galerija-slider/cat1', false, /\.(png|jpe?g|svg)$/))
const galImagesCat2 = importAll(require.context('./navigation/images/production/galerija-slider/cat2', false, /\.(png|jpe?g|svg)$/))
const galImagesCat3 = importAll(require.context('./navigation/images/production/galerija-slider/cat3', false, /\.(png|jpe?g|svg)$/))
const galImagesCat4 = importAll(require.context('./navigation/images/production/galerija-slider/cat4', false, /\.(png|jpe?g|svg)$/))
const galImagesCat5 = importAll(require.context('./navigation/images/production/galerija-slider/cat5', false, /\.(png|jpe?g|svg)$/))

export { imagesHome, galImagesCat1, galImagesCat2, galImagesCat3, galImagesCat4, galImagesCat5};