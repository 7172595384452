import React, { useState, useRef, useEffect } from "react";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

import Button from '@mui/material/Button';

import './predracun.css';
import { fetchPredracunIds, modifyDatabase } from "../../sources/databaseManager";

function PromiseWrapper({ promise, children }) {
  const [isResolved, setIsResolved] = useState(false);

  useEffect(() => {
    promise
      .then(() => setIsResolved(true))
      .catch((error) => console.error(error));
  }, [promise]);

  if (!isResolved) {
    return null; // or return a loading spinner
  }

  return children;
}

function PredracunHTML({className="", id="", data}) {
  const currentYear = new Date().getFullYear();
  const dateObj = new Date();
  const day = String(dateObj.getDate()).padStart(2, '0');
  const month = String(dateObj.getMonth() + 1).padStart(2, '0');
  const date = `${day}.${month}.${currentYear}`

  var price = 0;
  var priceDDV = 0;
  var name = "";

  if (data.price !== undefined) {
    price = data.price;
  } else alert('Cena manjka predračunu!');

  if (data.amount !== undefined) {
    price = Math.round((parseFloat(data.price) * parseFloat(data.amount)) * 100) / 100;
  }

  if (data.name !== undefined) {
    name = data.name;
  } else alert('Ime manjka predračunu!');

  const generateDDV = (price, tax=.22) => {
    let priceN = parseFloat(price) * tax + parseFloat(price);
    console.log('PriceN == ', priceN, priceN.toFixed(2));
    return priceN.toFixed(2);
  }

  priceDDV = generateDDV(price);

  const generatePrID = async () => {
    try {
      let dataID = await fetchPredracunIds();
      let lastID = dataID.data.values[dataID.data.values.length - 1];
      let stringPart = lastID.slice(0, 3)
      let num = parseFloat(lastID.slice(3, lastID.length)) + 1;
      let newID = stringPart + String(num).padStart(5, '0');
      let newIDS = dataID.data.values
      newIDS.push(newID);

      let success = await modifyDatabase('/predracunIDS', 'replace', 'values', newIDS);
      if (!success.success || !dataID.success) throw new Error('Error: Predracun ID not generated!');

      return newID;
    } catch (error) {
      console.log('Predracun ID == Error: ', error);
      return 'ERR00001';
    }
  }

  const [prID, setPrID] = useState('ERR00001');

  useEffect(() => {
    generatePrID().then((id) => setPrID(id));
  }, []);

  return(
  <div className={`pr-main ${className}`} id={id}>
    <div className="pr-top-cont">
      <div className="pr-top-left">
        <p>MIZARSTVO KARMEN D.O.O.</p>
        <p>Vrbnje 18/a</p>
        <p>4240 RADOVLJICA</p>
        <br />
      </div>
      <div className="pr-top-right">
        <p>tel: 041/751-543</p>
        <p>tel: 031/224-968</p>
        <a href="mailto:info@mizarstvo-karmen.si">info@mizarstvo-karmen.si</a>
        <p>ID št. za DDV: SI29272246</p>
      </div>
    </div>
    <div className="pr-top-text">
      <p className="small-text">
        Okrožno sodišče Kranj, datum regitracije 25.07.2002, št. reg. vpisa1/07071/00, osnovni kapital 8.763,00 eur.
      </p>
      <hr />
    </div>
    <div className="pr-textT-cont">
      <div className="pr-textT-left">
        <p>  </p>
        <p>  </p>
        <br />
        <p>  </p>
        <br />
        <h2>PREDRAČUN</h2>
        <br />
      </div>
      <div className="pr-textT-right">
        <div className="pr-textT-rightL">
          <p>Kraj izdaje predračuna:</p>
          <p>Datum predračuna:</p>
          <p>Veljavnost predračuna:</p>
          <p>ID št. za DDV:</p>
          <br />
          <h2>št. {
            prID
            }/{currentYear}</h2>
        </div>
        <div className="pr-textT-rightR">
          <p>  spletna-trgovina</p>
          <p>  {date}</p>
          <p>  30 dni</p>
          <p>  SI29272246</p>
        </div>
      </div>
    </div>
    <table className="pr-table">
      <thead>
        <tr>
          <th><hr /></th>
          <th><hr /></th>
          <th><hr /></th>
          <th><hr /></th>
          <th><hr /></th>
        </tr>
        <tr>
          <th>Vrsta storitve</th>
          <th>Merska enota</th>
          <th>Količina</th>
          <th>Cena brez DDV</th>
          <th>Cena z DDV</th>
        </tr>
        <tr>
          <th><hr /></th>
          <th><hr /></th>
          <th><hr /></th>
          <th><hr /></th>
          <th><hr /></th>
        </tr>
      </thead>
      <br />
      <tbody>
        <tr>
          <td>{name}</td>
          <td>kpl</td>
          <td>{data.amount}</td>
          <td>{price} eur</td>
          <td>{priceDDV} eur</td>
        </tr>
      </tbody>
    </table>
    <br />
    <br />
    <div className="pr-vrednosti">
      <div className="pr-vr-filler">

      </div>
      <div className="pr-vr-row">
        <hr />
        <div className="pr-vrednosti-cont" style={{flexDirection: "row", display: "flex"}}>
          <div>
            <p>SKUPAJ VREDNOST BREZ DDV</p>
            <p>DDV 22% od osnove</p>
            <strong><p>VREDNOST Z DDV V EUR</p></strong>
          </div>
          <div>
            <p>{price} eur</p>
            <p>{(priceDDV - price).toFixed(2)} eur</p>
            <strong><p>{priceDDV} eur</p></strong>
          </div>
        </div>
        <hr />
      </div>
    </div>
    <div className="pr-pogoji">
      <p>Plačilni pogoji:</p>
      <ul>
        <li>- 100% avans</li>
        <li>- naročilnica</li>
      </ul>
      <p>Rok izvedbe: </p>
      <ul>
        <li>- 7 delovnih dni od dneva prejema plačila</li>
        <li>- po dogovoru</li>
      </ul>
    </div>
    <div className="pr-footer">
      <strong><p>TRR Gorenjska banka d.d., Kranj: SI56 0700 0000 0766 778</p></strong>
      <p>Pri plačilu predračuna navedite sklic na številko: {prID}/{currentYear}</p>
      <br />
    </div>
    <div className="footewr-r">
      <p>Za Mizarstvo Karmen:</p>
      <p>Uroš Štefančič</p>
    </div>
  </div>
  );
}

function Predracun() {
  const predracunRef = useRef(null);

  //Getting the colors from root
  const primaryColor = getComputedStyle(document.documentElement).getPropertyValue('--primary-color');
  const hoverColor = getComputedStyle(document.documentElement).getPropertyValue('--accent-color');

  const handlePredracunPDF = async () => {
    const inputData = predracunRef.current;
    try {
      const canvas = await html2canvas(inputData);
      const imgData = canvas.toDataURL('image/png');
  
      const pdf = new jsPDF({
        orientation: 'portrait',
        unit: 'px',
        format: 'a4'
      });
  
      const width = pdf.internal.pageSize.getWidth();
      const height = pdf.internal.pageSize.getHeight();
  
      pdf.addImage(imgData, 'PNG', 0, 0, width, height);
      pdf.save('predracun.pdf');
    } catch (error) {
      console.log('PDF == Error: ', error);
    }
  }

  return(
    <div className="PDF-main">
      <div className="pdf-cont">
        <div ref={predracunRef}>
          <PredracunHTML 
            className="predracun" 
            id="predracun"
          />
        </div>
      </div>
      <div className="right-side-main">
        <div className="right-side-cont">
          <h2>
            -- Pregled Predračuna --
          </h2>
          <Button 
            className="download-button"
            onClick={() => handlePredracunPDF()}
            sx={{
              backgroundColor: primaryColor,
              color: 'white',
              '&:hover': {
                backgroundColor: hoverColor,
              }
            }}
          >
            Prenesi PDF
          </Button>
        </div>
      </div>
    </div>
  );
}

export { PredracunHTML };