import React, { useEffect, useState, useRef } from 'react';
import ReactDOM from 'react-dom/client';
import { Bar } from 'react-chartjs-2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import { getMonthName, makeViewGraphData, readStats } from '../../sources/stats.js';
import { readShopItems } from '../../sources/databaseManager.js';

import { generateShopItems } from './Store.jsx';
import StoreTopBar from './storeTopBar.jsx';
import App from '../../App.jsx';
import Footer from '../footer/footer.jsx';
import EditPage from './editPageStore.jsx';

import './adminDashboard.css';
import LoadingAnimation from '../loading animation/loading.jsx';

export default function AdminDashboard({email, password, className, startINDX=null}) {
  // Tab update function
  const updateActiveTab = (val) => {
    var tab1 = document.getElementsByClassName('admin-dshb-1')[0];
    var tab2 = document.getElementsByClassName('admin-dshb-2')[0];

    if (val === 0) {
      tab1.classList.remove('hidden');
      tab2.classList.add('hidden');

    } else if (val === 1) {
      tab1.classList.add('hidden');
      tab2.classList.remove('hidden');
    }
  }

  // Bar chart for Viewing the wesbite views
  function BarChart({xaxis=null, yaxis=null}) {
    const [xaxiis, setXaxis] = useState([]);
    const [yaxiis, setYaxis] = useState([]);
    const [graphRender, setGraphRender] = useState(false);

    useEffect(() => {
      makeViewGraphData().then((data) => {
        setXaxis(data[0]);
        setYaxis(data[1]);
      });
    }, []);

    if (xaxis === null && yaxis === null) {
      xaxis = xaxiis;
      yaxis = yaxiis;

      if (!xaxiis.length || !yaxiis.length) {
        return <h2>Loading-graph...</h2>;
      }
    }

    if (!xaxis.length || !yaxis.length) {
      return <h2>Loading-graph...</h2>;
    }

    if (graphRender === false) {
      const labelss = xaxis.map((item) => getMonthName(item));

      var data = {
        labels: labelss,
        datasets: [
          {
            label: 'number of views',
            data: yaxis,
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(255, 206, 86, 0.2)',
              'rgba(75, 192, 192, 0.2)',
              'rgba(153, 102, 255, 0.2)',
              'rgba(255, 159, 64, 0.2)',
            ],
            borderColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
              'rgba(75, 192, 192, 1)',
              'rgba(153, 102, 255, 1)',
              'rgba(255, 159, 64, 1)',
            ],
            borderWidth: 1,
          },
        ],
      };

      var options = {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              max: Math.max(...yaxiis)
            },
          }],
        },
      };

      setGraphRender(true)

      return <Bar data={data} options={options} className='bar-chart chart' />;
    }
  }

  const [shopItems, setShopItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [update, setUpdate] = useState(false);
  const [firstLoad, setFirstLoad] = useState(null);
  const [root, setRoot] = useState(null);

  useEffect(() => {
    setFirstLoad(true);
    if (root === null) {
      setRoot(ReactDOM.createRoot(document.getElementById('root')));
    }
  }, []);

  useEffect(() => {
    if (update === true || firstLoad === true) {
      setUpdate(false);
      setIsLoading(true);
      setFirstLoad(false);
      console.log('====== SITE update... ======')
      readShopItems({refresh: true}).then((data) => {setShopItems(data); setIsLoading(false);});
    }
    }, [update, firstLoad]);

  return (
    <>
      <div className='store-dashboard'>
        <div className='top-bar-adm'>
          <StoreTopBar onUpdate={(value) => updateActiveTab(value)} startINDX={startINDX}/>
          <button
            onClick={() => root.render(<App />)}
            className='return-button-ADM'
          >
            {'<'} nazaj
          </button>
        </div>
        <div className='main-cont-ADM'>
          {isLoading ? <LoadingAnimation boxed={true}/> : <div className={`admin-dshb-2 ${startINDX === 0 ? 'hidden': ''}`}>
            <div>
              <button 
                className='edit-btn'
                onClick={() => root.render(<EditPage className='edit-page-adm' manualData={false} setPageData={{
                  title: 'naslov',
                  description: 'opis',
                  price: 'cena',
                  "spec-category": 'barve',
                  specs: [
                    {
                      title: '++ -1-',
                      description: '++ -1-',
                      price: '==',
                      "spec-name": 'specifikacija 1',
                      image: 'https://placehold.co/600x400?text=Dodaj+sliko',
                    }
                  ],
                  tags: [],
                }} itemIndex={null} />)}
              >
                <FontAwesomeIcon icon={faPlus} />
              </button>
            </div>
            <div className='online-store-cont'>
              {generateShopItems({ 
                data: shopItems, 
                className: 'shop-items-cont-ADM', 
                adm: true,
                onDeleteChange: () => setUpdate(true),
                onEditChange: () => setUpdate(true),
                root: root
                })}
            </div>
          </div>}
          <Footer className="footer-main-adm"/>
        </div>
      </div>
    </>
  );
}