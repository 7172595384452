import React, { useState, useEffect } from 'react';

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { get, getDatabase, ref, set, update, push } from "firebase/database";

import PdfViewer from '../navigation/onlineStore/viewRacun.jsx';

// Initialize Firebase Database
const firebaseConfig = {
  apiKey: "AIzaSyDkq97C0BraNoUHLWq9pR9Sl9TqK84ffsE",
  authDomain: "mizarastvo-karmen.firebaseapp.com",
  databaseURL: "https://mizarastvo-karmen-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "mizarastvo-karmen",
  storageBucket: "mizarastvo-karmen.appspot.com",
  messagingSenderId: "33987227201",
  appId: "1:33987227201:web:db1b3f90c701d5906de0c0",
  measurementId: "G-YBDXEJP31B"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Mail sending
/**
 * This function sends an email to the specified address.
 * @param {Array} data - The data to be sent in the email.
 * @param {String} data.sender - The email address of the sender.
 * @param {String} data.subject - The subject of the email.
 * @param {String} data.message - The message to be sent in the email.
 * 
 * ---------------------------------
 * @returns {Object} - Returns an object with the success status and the error if there is one. 
 */
export async function sendMail(data) {
  let result = {};

  try {
    result = await fetch('http://192.168.1.108:5000/api/mail/send', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
    .then(response => {
      if (!response.ok) {
        throw new Error(`=== DATABSE === HTTP error! status: ${response.status}`);
      }
      return response.json();
    })
    .then(data => {
      if (data.success) {
        console.log('=== DATABSE === Mail sent successfully.');
        return {success: true, error: null};
      } else {
        console.error('=== DATABSE === Error sending mail:', data.error);
        return {success: false, error: data.error};
      }
    });
  } catch (error) {
    console.error('=== DATABSE === An error occurred while sending mail:', error);
  }

  return result;
}

// geting categories from databse
export async function getCategoryData() {
  const db = getDatabase();
  const categoriesRef = ref(db, '/categoryData');

  try {
    const snapshot = await get(categoriesRef);
    const data = snapshot.val();
    if (data && typeof data === 'object') {
      return { success: true, data: data };
    } else {
      console.error('=== DATABASE === Data received is not an object');
      return { success: false, error: 'Data received is not an object' };
    }
  } catch (error) {
    console.error("=== DATABASE === An error occurred while reading 'CategoryData':", error);
    return { success: false, error: error };
  }
}

// Parsing the specs
export function parseSpecs(specs, fullData) {
  var price, title, desc, image;
  var res = [];

  for (let j = 0; j < specs.length; j++) {
    let spec = specs[j];

    // PRICE
    if (spec.price.trim() === "==") {
      price = fullData.price;
    }
    else if (spec.price.trim().startsWith("++", 0) === true) {
      let priceR = parseInt(fullData.price) + parseInt((spec.price.split("++")[1]).trim());
      price = priceR.toString();
    }
    else {
      price = spec.price;
    }

    // TITLE
    if (spec.title.trim() === "==") {
      title = fullData.title;
    }
    else if (spec.title.startsWith("++", 0) === true) {
      let titleR = fullData.title + (spec.title.split("++")[1]);
      title = titleR;
    }
    else {
      title = spec.title;
    }

    // DESCRIPTION
    if (spec.description.trim() === "==") {
      desc = fullData.description;
    }
    else if (spec.description.startsWith("++", 0) === true) {
      let descriptionMD = fullData.description + (spec.description.split("++")[1]);
      desc = descriptionMD;
    }
    else {
      desc = spec.description;
    }

    // IMAGE
    image = spec.image;

    res.push(
      {
        title: title,
        description: desc,
        price: price,
        image: image,
        "spec-name": spec["spec-name"]
      }
    );
  };

  return res;
}

// Removing a spec from specSheet
export function removeSpec(data, specIndex, shopIndex) {
  data.specs.splice(specIndex, 1);
  return data;
}

// Get emailJS API key
export async function getEmailJSKey() {
  let result = {};
  let db = getDatabase(app);
  let path = '/APIkeys';

  try {
    const snapshot = await get(ref(db, path));
    const data = snapshot.val();
    if (data && typeof data === 'object') {
      result = data;
    } else {
      console.error('=== DATABASE === Data received is not an object');
    }
  } catch (error) {
    console.error('=== DATABASE === An error occurred while Reading database:', error);
  }

  return result.emailjs;
}

export async function getEmailJSPublicKey() {
  let result = {};
  let db = getDatabase(app);
  let path = '/APIkeys';

  try {
    const snapshot = await get(ref(db, path));
    const data = snapshot.val();
    if (data && typeof data === 'object') {
      result = data;
    } else {
      console.error('=== DATABASE === Data received is not an object');
    }
  } catch (error) {
    console.error('=== DATABASE === An error occurred while Reading database:', error);
  }

  return result.emailjsPUBLIC;
}

// Parsing the tags
export function parseTags(tags) {
  var res = tags.split(",");
  for (let i = 0; i < res.length; i++) {
    res[i] = res[i].trim().replace("#", "");
  }

  return res;
}

/**
 * This function updates the existing Category values since useState was unreliable for this purpose.
 * @param {Array} data - The data to be added to the CategoryData.
 * -----------------
 * @example <caption>Example usage of addCategoryData function.</caption> 
 * import { addCategoryData } from './sources/databaseManager.js';
 * 
 * returnValue = await addCategoryData({index: 0, value: true});
 */
export async function updateCategoryData(data) {
  let result = {};
  const db = getDatabase(app);
  const categoriesRef = ref(db, '/categoryData/values');

  let index = data.index;
  let value = data.value;
  
  try {
    let categories = (await get(categoriesRef)).val();

    categories[index][2] = value;
    await set(categoriesRef, categories);

    result = { success: true, data: categories };
  } catch (error) {
    console.error("=== DATABSE === An error occurred while adding data to 'CategoryData':", error);
  }

  return result;
}

/**
 * This is a function that gets the different variants for a item if there are any.
 * @param {Object} shopItem - The shopItem Title for which the variants are to be fetched.
 */
export async function getSpecs(shopItemTitle) {
  let db = await readDatabase();
  let shopItems = db.shopItems.values;

  let specs = shopItems.filter(item => item.name === shopItemTitle);
  
  if (specs.length > 1) {
    console.error('=== DATABASE === Multiple specs found for the same item.');
    return null;
  } else { 
    return specs[0].specs;
  }
}

/**
 * This function adds new categories to the CategoryData.
 * @param {Array} data - The data to be added to the CategoryData.
 * -----------------
 * @example <caption>Example usage of addCategoryData function.</caption>
 * import { addCategoryData } from './sources/databaseManager.js';
 * 
 * returnValue = await addCategoryData({index: 'default', value: 'default', name: 'someCategoryName'});
 * 
 * @returns {Object} - Returns an object with the success status and the data.
 */
export async function addCategoryData(data) {
  const db = getDatabase(app);
  const categoriesRef = ref(db, '/categoryData');

  try {
    const newCategoryRef = push(categoriesRef.values);
    await set(newCategoryRef, data);

    console.log('=== DATABASE === CategoryData successfully added.');
    return { success: true, data: data };
  } catch (error) {
    console.error("=== DATABASE === An error occurred while adding data to 'CategoryData':", error);
    return { success: false, error: error };
  }
}

export async function readDatabase() {
  let result = {};
  let db = getDatabase(app);
  let path = '/';

  try {
    const snapshot = await get(ref(db, path));
    const data = snapshot.val();
    if (data && typeof data === 'object') {
      result = data;
    } else {
      console.error('=== DATABASE === Data received is not an object');
    }
  } catch (error) {
    console.error('=== DATABASE === An error occurred while Reading database:', error);
  }

  return result;
}

export async function modifyDatabase(databasePath, action, dictKey, data) {
  const db = getDatabase(app);
  const dbRef = ref(db, databasePath);

  try {
    if (action === 'replace') {
      await set(dbRef, { [dictKey]: data });
    } else if (action === 'update') {
      await update(dbRef, { [dictKey]: data });
    } else {
      console.error('=== DATABASE === Invalid action:', action);
      return { success: false, error: 'Invalid action' };
    }

    console.log('=== DATABASE === Data successfully modified.');
    return { success: true, data: { [dictKey]: data } };
  } catch (error) {
    console.error('=== DATABASE === Error modifying data:', error);
    return { success: false, error: error };
  }
}

export async function generateAccountId() {
  const length = 8;
  var database = await readDatabase();

  var accountIds = database.accountData.ids;
  let id;

  do {
    id = Math.floor(Math.random() * Math.pow(10, length)).toString().padStart(length, '0');
  } while (accountIds.includes(id));
  accountIds.push(id);

  modifyDatabase('accoutnData', 'replace', 'ids', accountIds)

  return id;
}

// predracun IDS
export async function fetchPredracunIds() {
  const db = getDatabase(app);
  const predracunRef = ref(db, '/predracunIDS');

  try {
    const snapshot = await get(predracunRef);
    const data = snapshot.val();
    if (data && typeof data === 'object') {
      return { success: true, data: data };
    } else {
      console.error('=== DATABASE === Data received is not an object');
      return { success: false, error: 'Data received is not an object' };
    }
  } catch (error) {
    console.error("=== DATABASE === An error occurred while reading 'PredracunData':", error);
    return { success: false, error: error };
  }
}

// reading the database
var database = await readDatabase();

// // setting the defaults
// modifyDatabase('accountData', 'replace', "signedIn", false);
// modifyDatabase('accountData', 'replace', "currentAccount", {"email":"", "password":"", "id":""});

// Search Data
var shopItems = database.shopItems.values;

export async function refreshDatabaseData() {
  database = await readDatabase();

  shopItems = database.shopItems.values;

  return true;
}

// Shop Items
export async function readShopItems({refresh} = {}) {
  if (refresh !== undefined) {
    return shopItems;
  }
  else {
    await refreshDatabaseData();
    return shopItems;
  }
}

export const BuyItem = ({ title, description, price }) => {
  var payload = {
    title,
    description,
    price
  };

  var [Filename, setFilename] = useState(null);
  var [pdfUrl, setPdfUrl] = useState(null);

  useEffect(() => {
    fetch('http://192.168.1.108:5000/api/database/predracun', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
    .then(response => response.json())
    .then(data => {
      setFilename(data.filename);

      if (data.success) {
        console.log('=== DATABSE === Item succesfully bought.');
      } else {
        console.error('=== DATABSE === Error buying item:', data.error);
      }

      fetch(`http://192.168.1.108:5000/api/database/get-pdf/${Filename}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/pdf',
        }
      })
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        setPdfUrl(url);

        console.log('=== DATABSE === PDF URL:', url);
      })
      .catch(error => console.error('Error:', error));

    })
    .catch(error => {
      console.error('Error:', error);
    });
  }, [payload]);

  return (
    <div>
      {pdfUrl && <PdfViewer pdfUrl={pdfUrl} />}
    </div>
  );
}

export function addShopItem(data) {
  shopItems.push(data);

  modifyDatabase('/shopItems', 'replace', 'values', shopItems);
}

// ! STILL IN DEVELOPMENT
// TODO - Implement removeShopItem
export function removeShopItem(key) {
  shopItems = shopItems.filter((item) => item.key !== key);
}

console.log('=== DATABSE === Database Manager loaded.');