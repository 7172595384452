import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPencil } from '@fortawesome/free-solid-svg-icons';

import Navbar from "../navbar/navbarMain.jsx";
import LoadingAnimation from '../loading animation/loading.jsx';
import StoreItem from './StoreItem.jsx';
import AdminDashboard from './adminDashboard.jsx';
import EditPage from './editPageStore.jsx';

import { readShopItems, modifyDatabase, updateCategoryData, getCategoryData } from '../../sources/databaseManager.js';

import './Store.css';

import Checkbox from '@mui/material/Checkbox';
import Slider from '@mui/material/Slider';
import Box from '@mui/material/Box';
const primaryColor = getComputedStyle(document.documentElement).getPropertyValue('--primary-color');
var backDir = false;

function valuetext(value) {
  if (isNaN(value)) {
    return `${value}`
  }
  return `${value} €`;
}

function Categories({ data, specific, listINDX, doCategoryChange }) {
  // Replace the duplicates
  var dataNew = data.filter((item, index) => data.indexOf(item) === index);
  const defValues = dataNew.map((item, index) => [index, item, true]);

  const [response, setResponse] = useState(null);
  const [response2, setResponse2] = useState(null);
  const [read, setRead] = useState(false);
  const [read2, setRead2] = useState(false);

  useEffect(() => {
    modifyDatabase('/categoryData', 'replace', 'values', defValues)
    .then((data) => {
      setResponse(data);
      setRead(true);
    });
  }, []);

  if (response === null && read === false) {
    return <LoadingAnimation boxed={true}/>;
  }

  if (response.success) {
    // console.log('Categories replaced successfully!');
  }
  else {
    console.log(`Error replacing categories! {${response.error}}`);
    modifyDatabase('/categoryData', 'replace', 'values', defValues)
    .then((data) => {
      setResponse2(data);
      setRead2(true);
    });

    if (response2 === null && read2 === false) {
      return <LoadingAnimation boxed={true}/>;
    }

    if (response2.success) {
      // console.log('Categories replaced 2. time successfully!');
    }
    else {
      console.log(`Error replacing categories 2. time! {${response2.error}}`);
    }
  }
  
  const handleCategoryChange = async (event) => {
    const catIndx = event.target.name;
    const checked = event.target.checked;

    let response = null;

    response = await updateCategoryData({index: catIndx, value: checked})
    .then((data) => {
      return data;
    });
    if (response !== null) {
      if (response.success) {
        // console.log('Category updated successfully!');
      }
      else {
        console.log(`Error updating category! {${response.error}}`);
      }
      doCategoryChange(response.data);
    }
  };

  return (
    <>
      {dataNew.map((category, i) => (
        <div className='category' key={i}>
          <Checkbox
            style={{ color: primaryColor }}
            className={`checkbox-categories-${i} checkbox-categories`}
            name={i}
            defaultChecked={specific === true ? (i === listINDX ? true : false) : true}
            onChange={(event) => handleCategoryChange(event)}
          />
          <p>{category}</p>
        </div>
      ))}
    </>
  );
}

export function generateShopItems({ data, className, adm, onDeleteChange, onEditChange, root}) {
  const openShopItem = async (event, adm=false) => {
    const item = event.target;
    const itemIndex = item.name;
  
    let data = await readShopItems({refresh: true})  
   
    if (item.className.includes('shop-display-item')) {
      root.render(<StoreItem item={data[itemIndex]} adm={adm} />);
    }
  }
  
  const handleDeleteButton = async (event) => {
    let item = event.target.parentElement.parentElement;
    let itemIndex = parseInt(item.id.trim());
  
    if (isNaN(itemIndex) === true) {
      item = event.target.parentElement.parentElement.parentElement.parentElement;
      try {
        itemIndex = parseInt(item.id.trim());
      } catch (error) {
        console.log("error: ", error)
      }
    }
  
    data.splice(itemIndex, 1);
  
    let datar = await modifyDatabase('/shopItems', 'replace', 'values', data)
  
    if (datar.success) {
      alert('Izdelek je bil uspešno izbrisan!');
      root.render(<AdminDashboard startIndx={1}/>);
    }
    else {
      alert('Napaka pri brisanju izdelka!');
    }
    
  }
  
  const handleEditButton = async (event) => {
    let item = event.target.parentElement.parentElement;
    let itemIndex = parseInt(item.id.trim());
  
    if (isNaN(itemIndex) === true) {
      item = event.target.parentElement.parentElement.parentElement;
      try {
        itemIndex = parseInt(item.id.trim());
      } catch (error) {
        console.log("Error parsingInt: ", error)
      }
    }
  
    // Show loading animation while fetching shop items
    root.render(<LoadingAnimation />);
  
    // Fetch shop items
    let data = await getShopItems();
  
    if (data[itemIndex] === undefined) {
      data = await readShopItems({refresh: true});
  
      if (data[itemIndex] === undefined) {
        alert('Izdelek ni  bil najden!');
        root.render(<AdminDashboard startIndx={1}/>);
      }
      data[itemIndex].index = itemIndex;
  
    }
    else {
      data[itemIndex].index = itemIndex;
  
      // Render EditPage component with fetched shop item data
      root.render(<EditPage setPageData={data[itemIndex]} manualData={true} itemIndex={itemIndex} />);
    }
  }

  let items = [];
  backDir = true;

  for (let i = 0; i < data.length; i++){
    items.push(
      <div className={`shop-item shop-display-item ${className}`} id={i}>
        <div id={i} className={`${adm === true ? "" : "hidden"} shop-delete-btn`}>
          <button
            className='delete-btn'
            onClick={(event) => {handleDeleteButton(event); onDeleteChange(event);}}
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
          <button
            className='edit-shop-item-btn'
            onClick={(event) => {handleEditButton(event); onEditChange(event);}}
          >
            <FontAwesomeIcon icon={faPencil} />
          </button>
        </div>
        <img  
          className='item-image shop-display-item' 
          onClick={(event) => openShopItem(event, adm)}
          name={i}
          src={data[i].specs[0].image} 
          alt={data[i].title} 
          style={{ width:'400px', height:'400px', objectFit: 'contain'}}
        />
        <div className='item-description-container shop-display-item' name={i}>
          <h3 
            className='item-title shop-display-item'
            name={i}
            key={i}
          >
            {(() => {
            var name = data[i].title;
            var carLenth = 125;

            if (typeof name !== 'string') {
              return name;
            }

            else if (name.length > (carLenth / 2) && name.length < carLenth) {
              return[name, <><br/><br/></>];
            }

            else if (name.length < (carLenth / 2)) {
              return [name, <><br/><br/><br/></>];
            }

            else if (name.substring(0, carLenth)[carLenth-1] === " ") {
              return name.substring(0, carLenth) + " ...";
            }
              
            else {
              return name.substring(0, carLenth) + "- ...";
            }
            })()}
          </h3>
          <p 
            className='item-description shop-display-item'
            name={i} 
          >
            {(() => {
            var description = data[i].description;
            var carLenth = 125;

            if (typeof description !== 'string') {
              return description;
            }

            else if (description.length > (carLenth / 2) && description.length < carLenth) {
              return[description, <><br/><br/></>];
            }

            else if (description.length < (carLenth / 2)) {
              return [description, <><br/><br/><br/></>];
            }

            else if (description.substring(0, carLenth)[carLenth-1] === " ") {
              return description.substring(0, carLenth) + " ...";
            }
              
            else {
              return description.substring(0, carLenth) + "- ...";
            }
            })()}
          </p>
          <p 
            className='item-price shop-display-item'
            name={i}
          >
            <strong>{valuetext(data[i].price)}</strong>  <br /><em>{data[i].tags.map((tag) =>"#"+ tag).join(", ")}</em>
          </p>
          </div>
      </div>
    );
  }



  return <div className={className}>{items}</div>;
}

export async function getCategories() {
  let data = await readShopItems({refresh: true});
  let categorieValues = setCategoryItems(data);

  return categorieValues;
}

export async function getShopItems() {
  let data = await readShopItems({refresh: true})
  return data;
}

export function setCategoryItems (shopItems) {
  let result = [];

  for (let k = 0; k < shopItems.length; k++) {
    let tags = shopItems[k].tags;
    for (let l = 0; l < tags.length; l++) {
      if (!result.includes(tags[l])) {
        result.push(tags[l]);
      }
    }
  }

  if (!result.includes('Ostalo')) {
    result.push('Ostalo')
  }

  return result;
}

function Store (props) {
  const sortShopItemsCat = async () => {
    let selectedCatgsR = await getCategoryData();
    let selectedCatgs = [];

    if (selectedCatgsR.success === true) {
      selectedCatgs = selectedCatgsR.data.values;
    }
    else {
      console.log('Error getting category data: ', selectedCatgsR.error)
      return null;
    }

    let items = document.querySelectorAll('.shop-item');
    let hiddenTags = [];
  
    for (let h = 0; h < selectedCatgs.length; h++) {
      if (selectedCatgs[h][2] === false) {
        hiddenTags.push(selectedCatgs[h][1]);
      }
    }
  
    for (let i = 0; i < items.length; i++) {
      let tags = shopItems[i].tags;
  
      // Check if all tags of the item are in the hiddenTags array
      let hidden = tags.every(tag => hiddenTags.includes(tag));
  
      if (hidden) {
        items[i].style.display = 'none';
      } else {
        items[i].style.display = 'block';
      }
    }

    return hiddenTags;
  }
  
  const sortShopItemsPrice = async (hidden) => {
    hidden = await hidden;
    let items = document.querySelectorAll('.shop-item');
    let min = parseInt(document.querySelector('.min-input').value);
    let max = parseInt(document.querySelector('.max-input').value);
  
    for (let i = 0; i < items.length; i++) {
      let price = shopItems[i].price;
      let tags = shopItems[i].tags;

      if (!isNaN(parseInt(price))) {
        // Check if at least one tag of the item is not in the hidden array
        let someTagsVisible = tags.some(tag => !hidden.includes(tag));

        if ((parseInt(price) < min || parseInt(price) > max) || !someTagsVisible) {
          items[i].style.display = 'none';
        } else {
          items[i].style.display = 'block';
        }
      }
    }
  }
  
  const sortShopItems = () => {
    let hiddenTags = sortShopItemsCat();
    sortShopItemsPrice(hiddenTags);
  }
  
  const handleCategoryChange = (event) => {
    sortShopItems();
  } 

  const getShopPrices = (shopItems) => {
    if (shopItems.filter((item) => !isNaN(parseInt(item.price))).length === shopItems.length) {
      return shopItems;
    }
    else {
      for (let price of shopItems.map((item) => item.price)) {
        if (isNaN(parseInt(price))) {
          return getShopPrices(shopItems.filter((item) => item.price !== price));
        }
      }
    }
  }

  // Props
  const specific = props.specific;
  const listINDX = props.listINDX;

  // Hnadling the view and stats
  const [Loading, setLoading] = useState(true);
  const [firstLoad, setFirstLoad] = useState(true);
  const [root, setRoot] = useState(null);
  const [shopItems, setShopItems] = useState([]);

  useEffect(() => {
    if (root === null) {
      setRoot(ReactDOM.createRoot(document.getElementById('root')));
    }

    if (firstLoad) {
      // Call your function that sets Loading to false here
      // For example:
      readShopItems({refresh: true}).then((data) => {
        setTimeout(() => {
          setShopItems(data);
          setLoading(false);
          setFirstLoad(false);
          console.log("shop items loaded...")
        }, 1000);
      });
    }
  }, []);

  // get the necasery search data
  const [value2, setValue2] = useState(null);

  useEffect(() => {
    try {
      const maxDistance = Math.max(...getShopPrices(shopItems).map(item => parseInt(item.price)));
      setValue2([0, maxDistance]);
    } catch (error) {
      console.log("Error setting value2: ", error)
    }
  }, [shopItems]);

  // useEffect(() => {
  //   setTimeout(() => {
  //     Loading = false;
  //   }, 1000);
  // }, [Loading]);

  if (Loading === true) {
    return <LoadingAnimation />;
  }

  const maxDistance = Math.max(...getShopPrices(shopItems).map(item => parseInt(item.price)));
  const minDistance = maxDistance / 10;

  const handleChangePrice = (event, newValue, activeThumb, defaultVal=false) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (defaultVal) {
      newValue[0] = 0;
      newValue[1] = maxDistance;
    }

    const maxInput = document.querySelector('.max-input');
    const minInput = document.querySelector('.min-input');

    setTimeout(() => {
      if (newValue[1] - newValue[0] < minDistance) {
        if (activeThumb === 0) {
          const clamped = Math.min(newValue[0], maxDistance - minDistance);
          setValue2([clamped, clamped + minDistance]);
        } else {
          const clamped = Math.max(newValue[1], minDistance);
          setValue2([clamped - minDistance, clamped]);
        }
      } else {
        setValue2(newValue);
      }
      minInput.value = newValue[0];
      maxInput.value = newValue[1];
    }, 0);

    sortShopItems();
  };

  const categorieData = setCategoryItems(shopItems)

  return (
    <>
    {Loading === true && firstLoad === true ? <LoadingAnimation /> : <div className='Store SiteBG'>
      <Navbar className="navbar"/>
      {/* <section id='TopBar'>
        <div className='top-bar'>
          <div className='top-bar-container'>
            <div className='welcome-h1-store'>
              <h1>Spletna trgovina</h1>
            </div>
            <div className='search-box-store'>
              <ReactSearchBox 
                id='search-box-comp'
                placeholder='Search here'
                data={searchData}
                dropDownHoverColor='#f5f5f5'
                onSelect={(event) => handleSearch(event)}
              />
            </div>
          </div>
        </div>
      </section> */}

      <section id='StoreContents'>
        <div className='left-side'>
          <div className='left-container'>
            <h2>Categories</h2>
              <Categories 
                data={categorieData} 
                specific={specific} 
                listINDX={listINDX}
                doCategoryChange={handleCategoryChange}
              />
            <h2>Cena</h2>
            <div className='input-boxes'>
              <input type='text' placeholder='Min €' className='min-input val-input'
                onChange={(e) => handleChangePrice(e, [e.target.value, value2[1]])}
                defaultValue={0}
              />
              <input type='text' placeholder='Max €' className='max-input val-input'
                onChange={(e) => handleChangePrice(e, [value2[0], e.target.value])}
                defaultValue={maxDistance}
              />
            </div>
            <Box className="slider-box">
              <Slider
                getAriaLabel={() => 'Minimum distance shift'}
                value={value2}
                onChange={handleChangePrice}
                max={maxDistance}
                valueLabelDisplay="auto"
                valueLabelFormat={(value) => `${value} €`}
                getAriaValueText={(value) => valuetext(value)}
                style={{margin: "0 0 0 20px", color: primaryColor}}
                disableSwap
              />
            </Box>
          </div>
        </div>
        <div className='right-side'>
          <div className='right-container'>
            <div className='shop-items'>
              {generateShopItems({ 
                data: shopItems, 
                className: 'shop-items-inner', 
                adm: false,
                root: root,
              })}
            </div>
          </div>
        </div>
      </section>
      {/* <div className='footer-store'>
        <Footer />
      </div> */}

    </div>}
    </>
  );
};

export default Store;