import './App.css';
import ReactDOM from 'react-dom/client';
import React, { useRef, useEffect, useState } from 'react';

import Store  from './navigation/onlineStore/Store.jsx';
import Navbar from './navigation/navbar/navbarMain.jsx';
import LoadingAnimation from './navigation/loading animation/loading.jsx';

import { useIsScreenSmall, useIsScreenSize } from './sources/stats.js';

import { Navigation, Pagination, A11y, Autoplay, EffectCreative, EffectCoverflow } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore from 'swiper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartShopping } from '@fortawesome/free-solid-svg-icons';
import sendEmail from './sources/sendMail.js';
import $ from 'jquery';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/a11y';
import 'swiper/css/autoplay';
import 'swiper/css/effect-creative';
import 'swiper/css/effect-cube';
import 'swiper/css/effect-coverflow';
import 'swiper/css/effect-flip';
import 'swiper/css/free-mode';
import 'swiper/css/thumbs';
import 'swiper/swiper-bundle.css';

import { 
  imagesHome,
  galImagesCat1,
  galImagesCat2,
  galImagesCat3,
  galImagesCat4,
  galImagesCat5
} from './imageImport';
import Footer from './navigation/footer/footer.jsx';

SwiperCore.use([Navigation]);

function App({ navigateTo='none', signedIn=false }) {
  // Autoplay progress bar
  const progressBar = useRef(null);
  const progressContent = useRef(null);

  const DoonAutoplayTimeLeft = (s, time, progress) => {
    if (progressBar.current) {
      progressBar.current.style.setProperty('--progress', 1 - progress);
    }
    if (progressContent.current) {
      progressContent.current.textContent = `${Math.ceil(time / 1000)}s`;
    }
  };

  // Mail handling
  const [messageSent, setMessageSent] = useState(null);

  const generateSwiperSlide = (images, sort) => {
    let homeImages = images.map((image, index) => {
      return (
        <SwiperSlide key={index}>
          <div className='swiper-box'>
            <img src={image} alt="" />
          </div>
        </SwiperSlide>
      );
    });

    let galerijaImages = images.map((image, index) => {
      return (
        <SwiperSlide key={index}>
          <div className='galerija-box'>
            <img src={image} alt="" />
          </div>
        </SwiperSlide>
      );
    });

    let result;
    switch (sort) {
      case 'home':
        result = homeImages;
        break;
      case 'galerija':
        result = galerijaImages;
        break;
      default:
        result = homeImages;
        break;
    }

    return result;
  }

  // The transition duration and style
  const TransitionDuration = 800;
  const TransitionStyle = 'easeInOutQuart';

  // Swiper cube moving
  const galerijaSwiperRef = useRef(null);
  const [selectedSwiper, setSelectedSwiper] = useState(0);
  const slideEffect = 'coverflow';

  const goToSlide = (index) => {
    setSelectedSwiper(index);
  };

  // Loading animation
  const [isLoading, setIsLoading] = useState(true);
  const [root, setRoot] = useState(null);

  const sendMail = async (event) => {
    event.preventDefault();

    setMessageSent("loading");

    const subject = event.target.subject.value;
    const email = event.target.email.value;
    const message = event.target.message.value;

    let response = await sendEmail(event, subject, email, message);
    console.log(response);
    if (response === 1) {
      setMessageSent(true);
    }
    else {
      setMessageSent(false);
    }
  }

  useEffect(() => {
    // make ReactDOM root
    if (root === null) {
      setRoot(ReactDOM.createRoot(document.getElementById('root')));
    }

    // Simulate a loading delay
    setTimeout(() => {
      setIsLoading(false);
    }, 700);
  }, []);

  //navigate to a custom location on the page if navbar calls
  useEffect(() => {
    if (!isLoading && navigateTo !== 'none') {
      setTimeout(() => {
        const element = document.getElementById(navigateTo);
        if (element) {
          $('html, body').animate({ 
            scrollTop: $(element).offset().top - 150
          }, 800, 'swing');
        }
      }, 100);
    }
  }, [navigateTo, isLoading]);

  // Checking if screen is less than 950px
  const isScreenSmall = useIsScreenSmall();
  const isScreenSmall450 = useIsScreenSize({size: 450, smallerThan: true});
  const isScreenSmall650 = useIsScreenSize({size: 650, smallerThan: true});
  const isScreenSmall950 = useIsScreenSize({size: 950, smallerThan: true});
  const isScreenSmall1300 = useIsScreenSize({size: 1300, smallerThan: true});


  return (
    <div className="App">
        {isLoading ? <LoadingAnimation /> : <div>
          <section id='navbar'>
            <Navbar 
              TransitionDuration={TransitionDuration} 
              TransitionStyle={TransitionStyle}
              signedIn={false}
            />
          </section>

          <section id='home' name='home-sect'>
            <div className='container-home'>
              <div className='Swiper-cont'>
                <Swiper
                  className='Swiper-Main'
                  grabCursor={true}
                  modules={[ Pagination, A11y, Autoplay, EffectCreative]}
                  spaceBetween={50}
                  slidesPerView={1}
                  height={500}
                  rewind={true}
                  effect={'creative'}
                  pagination={{ 
                    clickable: true, 
                    dynamicBullets: true
                  }}
                  creativeEffect={{
                    prev: {
                      shadow: false,
                      translate: [0, 0, -400],
                    },
                    next: {
                      translate: ['100%', 0, 0],
                    },
                  }}
                  autoplay={{
                    delay: 7000,
                    disableOnInteraction: false,
                  }}
                  onAutoplayTimeLeft={ DoonAutoplayTimeLeft }
                >
                  {generateSwiperSlide(imagesHome, "home")}
                </Swiper>
              </div>
              <div className="autoplay-progress">
                <div className="progress-bar" ref={progressBar}></div>
                <span ref={progressContent}></span>
              </div>
            </div>
          </section>

          <section id='about' name='about-sect'>
            <div className='container-about'>
              <div className='about-text'>
                <h2>O nas</h2>
                <p>
                  Podjetje <strong>Mizarstvo Karmen d.o.o.</strong> je kreativno in inovativno mizarsko podjetje, ki z lastnim znanjem, izkušnjami, predanosti estetski lepoti in funkcionalnosti izdeluje unikatno pohištvo po željah naročnika, za zasebnike posebnih želja ter podjetja in organizacije velikih načrtov.
                  
                  MIZARSTVO KARMEN, podjetje za mizarstvo, trgovino in druge storitve, d.o.o. <br />
                  <strong>Vrbnje 18 A</strong><br />
                  <strong>4240 Radovljica</strong> <br />  
                  <br />
                  <strong>Gsm:</strong> 041 751 543 Uroš Štefančič <br />
                  <strong>Gsm:</strong> 031 224 968 Darja Štefančič <br />
                  {/* <strong>Tel./ Fax:</strong> 08 3810 339 <br /> */}
                  <strong>E-mail:</strong> info@mizarstvo-karmen.si <br />
                  <br />
                  <strong>Nekatere reference:</strong> <br />
                  Šolski center Kranj,  <br />
                  Saval sanacije dimnikov Aleš Avsenek s.p., <br />
                  Les & Veho d.o.o, <br />
                  5 Fiver, d.o.o., <br />
                  Phajton d.o.o <br />
                  <br />
                  Za dodatne informacije pokličite na eno od telefonskih številk ali izpolnite elektronski obrazec, na katerega vam bomo z veseljem odgovorili.
                </p>
              </div>
            </div>
          </section>

          <section id='galerija' name='galerija-sect'>
            <div className='container-galerija'>
              <div className='galerija-text'>
                <h3>Galerija</h3>
                <p>
                  Nekaj slik iz naše galerije
                </p>
              </div>
              { selectedSwiper === 0 ?
                <Swiper 
                  className='galerija-swiper'
                  modules={[ Pagination, EffectCoverflow ]}
                  grabCursor={true}
                  slidesPerView={isScreenSmall ? 1 : 3}
                  effect={slideEffect}
                  nested={true}
                  pagination={{ 
                    clickable: false,
                    dynamicBullets: true
                  }}
                  coverflowEffect={{
                    rotate: 50,
                    stretch: 10,
                    depth: 100,
                    modifier: 1.3,
                    slideShadows: false,
                  }}
                  onSwiper={(swiper) => { galerijaSwiperRef.current = swiper; }}
                >
                  {generateSwiperSlide(galImagesCat1, "galerija")}
                </Swiper>
                : (selectedSwiper === 1 ?
                  <Swiper 
                    className='galerija-swiper'
                    modules={[ Pagination, EffectCoverflow ]}
                    grabCursor={true}
                    slidesPerView={isScreenSmall ? 1 : 3}
                    effect={slideEffect}
                    pagination={{ 
                      clickable: false,
                      dynamicBullets: true
                    }}
                    coverflowEffect={{
                      rotate: 50,
                      stretch: 10,
                      depth: 100,
                      modifier: 1.3,
                      slideShadows: false,
                    }}
                    onSwiper={(swiper) => { galerijaSwiperRef.current = swiper; }}
                  >   
                    {generateSwiperSlide(galImagesCat2, "galerija")}
                  </Swiper>
                : (selectedSwiper === 2 ?
                  <Swiper 
                    className='galerija-swiper'
                    modules={[ Pagination, EffectCoverflow ]}
                    grabCursor={true}
                    slidesPerView={isScreenSmall ? 1 : 3}
                    effect={slideEffect}
                    pagination={{ 
                      clickable: false,
                      dynamicBullets: true
                    }}
                    coverflowEffect={{
                      rotate: 50,
                      stretch: 10,
                      depth: 100,
                      modifier: 1.3,
                      slideShadows: false,
                    }}
                    onSwiper={(swiper) => { galerijaSwiperRef.current = swiper; }}
                  >
                    {generateSwiperSlide(galImagesCat3, "galerija")}
                  </Swiper>
                : (selectedSwiper === 3 ?
                  <Swiper 
                    className='galerija-swiper'
                    modules={[ Pagination, EffectCoverflow ]}
                    grabCursor={true}
                    slidesPerView={isScreenSmall ? 1 : 3}
                    effect={slideEffect}
                    pagination={{ 
                      clickable: false,
                      dynamicBullets: true
                    }}
                    coverflowEffect={{
                      rotate: 50,
                      stretch: 10,
                      depth: 100,
                      modifier: 1.3,
                      slideShadows: false,
                    }}
                    onSwiper={(swiper) => { galerijaSwiperRef.current = swiper; }}
                  >
                    {generateSwiperSlide(galImagesCat4, "galerija")}
                  </Swiper>
                : (selectedSwiper === 4 ?
                  <Swiper 
                    className='galerija-swiper'
                    modules={[ Pagination, EffectCoverflow ]}
                    grabCursor={true}
                    slidesPerView={isScreenSmall ? 1 : 3}
                    effect={slideEffect}
                    pagination={{ 
                      clickable: false,
                      dynamicBullets: true
                    }}
                    coverflowEffect={{
                      rotate: 50,
                      stretch: 10,
                      depth: 100,
                      modifier: 1.3,
                      slideShadows: false,
                    }}
                    onSwiper={(swiper) => { galerijaSwiperRef.current = swiper; }}
                  >
                    {generateSwiperSlide(galImagesCat5, "galerija")}
                  </Swiper>
                : null))))}
              <Swiper
                className='galerija-selct-swiper'
                modules={[ Pagination ]}
                pagination={{
                  clickable: true,
                  type: 'progressbar',
                }}
                slidesPerView={isScreenSmall450 ? 1 : isScreenSmall650 ? 2 : isScreenSmall950 ? 3 : isScreenSmall1300 ? 4 : 5}
              >
                <SwiperSlide onClick={() => goToSlide(0)}>
                  <div className='galerija-box-select'>
                    <img src={galImagesCat1[0]} alt="" />
                    <h2>Naslov 1</h2>
                  </div>
                </SwiperSlide>
                <SwiperSlide onClick={() => goToSlide(1)}>
                  <div className='galerija-box-select'>
                    <img src={galImagesCat2[1]} alt="" />
                    <h2>Naslov 2</h2>
                  </div>
                </SwiperSlide>
                <SwiperSlide onClick={() => goToSlide(2)}>
                  <div className='galerija-box-select'>
                    <img src={galImagesCat3[0]} alt="" />
                    <h2>Naslov 3</h2>
                  </div>
                </SwiperSlide>
                <SwiperSlide onClick={() => goToSlide(3)}>
                  <div className='galerija-box-select'>
                    <img src={galImagesCat4[0]} alt="" />
                    <h2>Naslov 4</h2>
                  </div>
                </SwiperSlide>
                <SwiperSlide onClick={() => goToSlide(4)}>
                  <div className='galerija-box-select'>
                    <img src={galImagesCat5[0]} alt="" />
                    <h2>Naslov 5</h2>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </section>

          <section id='RedirectStore' name='storeRedirect-sect'>
            <div className='RD-Store-cont'>
              <div className='RD-right-side'>
                <div className='spletna-trgovina-cont-BP'>
                  <h2>Spletna Trgovina</h2>
                  <FontAwesomeIcon icon={faCartShopping} className='shopping-icon'/>
                </div>
                <p className='RD-text-mn'>
                  V naši spletni trgovini lahko najdete različne izdelke, ki so na voljo za nakup. 
                  V kolikor želite naročiti izdelek, ki ga ni v spletni trgovini, nas kontaktirajte preko obrazca na dnu strani.
                </p>
                <button className='RD-button' onClick={() => root.render(<Store />)}>
                  Spletna Trgovina
                </button>
              </div>
            </div>
          </section>

          <section id='contact' name='contact-sect'>
            <div className='container-contact'>
              <div className='contact-submition'>
              <form  
                onSubmit={(e) => sendMail(e)}
              >
                  <div className='form-group'>
                    <label htmlFor="subject">Zadeva</label>
                    <input type="text" id='subject' name='subject' />
                  </div>
                  <div className='form-group'>
                    <label htmlFor="email">Email</label>
                    <input type="email" id='email' name='email' />
                  </div>
                  <div className='form-group'>
                    <label htmlFor="message">Sporočilo</label>
                    <textarea id='message' name='message'></textarea>
                  </div>
                  <div className='form-group'>
                    <button type='submit'>Pošlji</button>
                  </div>
                </form>
                {messageSent === null? null : messageSent === "loading"? 
                  <p
                    style={{color: 'blue'}}
                  >
                    Pošiljanje sporočila...
                  </p>
                    : 
                    messageSent? 
                    <p
                      style={{color: 'green'}}
                    >
                      Sporočilo je bilo uspešno poslano!
                    </p> 
                    : 
                    <p
                      style={{color: 'red'}}
                    >
                      Sporočilo ni bilo poslano! Poskusite ponovno kasneje.
                    </p>
                }
              </div>
              <div className='fill-contact'>

              </div>
              <div className='contact-text'>
                <h2>Kontakt</h2>
                <p>
                  MIZARSTVO KARMEN, podjetje za mizarstvo, trgovino in druge storitve, d.o.o. <br />
                  Vrbnje 18 A <br />
                  4240 Radovljica <br />  
                  <br />
                  <strong>Gsm:</strong> 041 751 543 Uroš Štefančič <br />
                  <strong>Gsm:</strong> 031 224 968 Darja Štefančič <br />
                  {/* <strong>Tel./ Fax:</strong> 08 3810 339 <br /> */}
                  <strong>E-mail:</strong> info@mizarstvo-karmen.si <br />
                  <br />
                 <strong>Davčna št.: </strong>SI29272246 <br/>
                 <strong>Matična št.: </strong>1474839 <br/>
                </p>
              </div>
            </div>
          </section>

          <Footer className='footer-main-app'/>
        </div>}
    </div>
  );
}

export default App;
