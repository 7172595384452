import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom/client';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

import './StoreItem.css';
import Store from './Store.jsx';
import Navbar from '../navbar/navbarMain.jsx';
import AdminDashboard from './adminDashboard.jsx';
import { PredracunHTML } from '../predracun/predracun.jsx';

import Checkbox from '@mui/material/Checkbox';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { parseSpecs } from '../../sources/databaseManager.js';

function StoreItem({ item, adm }) {
  const [root, setRoot] = useState(null);
  const primaryColor = getComputedStyle(document.documentElement).getPropertyValue('--primary-color');

  const [downloadVisable, setDownloadVisable] = useState(false);
  const [trrBuy, setTrrBuy] = useState(false);

  const [selectedPrice, setSelectedPrice] = useState(item.price);
  const [selectedTitle, setSelectedTitle] = useState(item.title);
  const [selectedSpec, setSelectedSpec] = useState(item.specs[0]["spec-name"]);
  const [selectedDescription, setSelectedDescription] = useState(item.description);
  const [selectedImage, setSelectedImage] = useState(item.specs[0].image);
  const [selectedAmount, setSelectedAmount] = useState(1);

  const [DPvalue, setDPvalue] = useState(null);

  const handleBuy = () => {
    if (trrBuy) {
      if (!downloadVisable) {
        setDownloadVisable(true);
      }
      else {
        alert('Predračun je že navoljo za nalagati!');
      }
    }
    else {
      alert('Izberite plačilni način!');
    }
  }

  // handle checkbox change
  function handleCheckboxChange(event) {
    if (event.target.checked) {
      setTrrBuy(true);
    }
    else {
      setTrrBuy(false);
    }
  }

  function ItemSpecs({ className, data, onDataModify=() => {return null;}}) {
    const changeSpecItem = (event) => {
      let specsNew = parseSpecs([event.target.value], data);

      // TITLE
      setSelectedTitle(specsNew[0].title);

      // DESCRIPTION
      setSelectedDescription(specsNew[0].description);

      // PRICE
      setSelectedPrice(specsNew[0].price);

      // IMAGE
      setSelectedImage(specsNew[0].image);

      // SPEC
      setSelectedSpec(event.target.value["spec-name"]);

      // Dropdown value
      let dropdown = document.getElementById('select-specs');
      dropdown.value = event.target.value;
      setDPvalue(event.target.value);

      onDataModify({
        title: selectedTitle,
        description: selectedDescription,
        price: selectedPrice,
        "spec-name": selectedSpec,
        image: selectedImage
      });

      console.log("Spec item -> ", event.target.value);
    }

    return (
      <FormControl fullWidth sx={{
        marginTop: '35px',
      }}>
        <InputLabel id='select-specs'>{data["spec-category"].toUpperCase()}</InputLabel>
        <Select
          labelId='select-specs'
          id='select-specs'
          label={data["spec-category"].toUpperCase()}
          onChange={(event) => changeSpecItem(event)}
          className={className}
          value={DPvalue}
          sx={{
            color:"black"
          }}
        >
          {
            data.specs.map((item, index) => {
              return (
                <MenuItem
                  value={data.specs[index]}
                >
                  { item["spec-name"] }
                </MenuItem>
              )
            })
          }
        </Select>
      </FormControl>
    );
  }

  useEffect(() => {
    if (root === null) {
      setRoot(ReactDOM.createRoot(document.getElementById('root')));
    }
  }, []);

  // Handle predracuni
  const predracunRef = useRef(null);

  const handlePredracunPDF = async () => {
    let predracunDiv = document.getElementById('pdf');
    predracunDiv.classList.remove('hidden');

    const inputData = predracunRef.current;
    try {
      const canvas = await html2canvas(inputData);
      const imgData = canvas.toDataURL('image/png');
  
      const pdf = new jsPDF({
        orientation: 'portrait',
        unit: 'px',
        format: 'a4'
      });
  
      const width = pdf.internal.pageSize.getWidth();
      const height = pdf.internal.pageSize.getHeight();
  
      pdf.addImage(imgData, 'PNG', 0, 0, width, height);
      pdf.save('predracun.pdf');

      predracunDiv.classList.add('hidden');
    } catch (error) {
      console.log('PDF == Error: ', error);
    }
  }

  return (
    <div className='shop-item-FS-main'>
      <Navbar />
      <div className="shop-item-FS">
        <div className='shop-FS-left-side'>
          <img src={selectedImage} alt={selectedTitle} />
        </div>
        <div className='shop-FS-middle-side'>
          <h3>{selectedTitle}</h3>
          <p>{selectedDescription}</p>
          <br />
          <br />
          <p>Specifikacije:</p>
          <ItemSpecs data={item} />
          <br />
          <p>Količina: </p>
          <FormControl fullWidth sx={{
            marginTop: '35px',
          }}>
            <InputLabel id='select-amount'>Količina</InputLabel>
            <Select
              labelId='select-amount'
              id='select-amount'
              label='Količina'
              onChange={(event) => setSelectedAmount(event.target.value)}
              value={selectedAmount}
              sx={{
                color:"black"
              }}
            >
              {
                Array.from({length: 10}, (_, i) => i + 1).map((item, index) => {
                  return (
                    <MenuItem
                      value={item}
                    >
                      { item }
                    </MenuItem>
                  )
                })
              }
            </Select>
          </FormControl>

        </div>
        <div className='shop-FS-right-side'>
          <div className='shop-FS-right-cont'>
            <h3>Cena: </h3><p>(brez DDV)</p>
            <p className='FS-price'>{selectedPrice} €</p>
            <div className='checkbox-container' style={{display:"flex", flexDirection:"row", alignItems: "center"}}>
                <Checkbox
                  className='Pay-checkbox'
                  style={{color: primaryColor}} 
                  onChange={(event) => handleCheckboxChange(event)}
                />
                <p>
                  Predračun preko TRR
                </p>
              </div>
            <button 
              onClick={() => {
                adm === true ? alert("Za kupovanje izdelkov je potrebno iti na glavno stran spletne strani.") 
                : 
                handleBuy()
              }}
            >KUPI</button>
            {downloadVisable? 
              <button 
                onClick={() => handlePredracunPDF()}
                className='download-button'
              >
                Naložite predračun
              </button>
            : null
            }
          </div>
          <div id="pdf" className="pdf-cont hidden">
            <div ref={predracunRef}>
              <PredracunHTML className="pdf-cont-mn" data={{price: selectedPrice, name: `${selectedTitle} - ${selectedSpec}`, amount:selectedAmount}}/>
            </div>
          </div>
        </div>
      </div>
      <button 
        onClick={() => {adm === true ? root.render(<AdminDashboard startINDX={1}/>) : root.render(<Store />)}}
        className='return-button-FS'
      >
        {'<'} nazaj
      </button>
    </div>
  );
}

export default StoreItem;